<template>
  <v-card>
    <v-card-title class="d-flex align-center flex-wrap justify-space-between" style="gap: 1rem">
      <div class="d-flex align-center" style="gap: 1rem; flex: 1; min-width: 15rem">
        <v-icon v-if="icon" class="mx-2" v-text="icon"></v-icon>
        <slot name="action">
          <v-btn
            v-test-id="'new-button'"
            v-if="!hideNewButton && _newRoute"
            :to="_newRoute"
            small
            depressed
            fab
            text
            exact
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </slot>
        <slot name="title">
          {{ title }}
        </slot>
      </div>

      <v-expand-transition>
        <div v-if="!isCollapsed" class="d-flex align-center flex-wrap" style="gap: 1rem; flex: 2">
          <slot name="search">
            <v-text-field
              v-test-id="'table-search'"
              v-if="searchable"
              v-model="search"
              @keydown.enter="forceLoad++"
              append-icon="mdi-magnify"
              class="mt-0 pt-0"
              label="Search"
              style="flex: 1; min-width: 15rem"
              outlined
              dense
              clearable
              single-line
              hide-details
            ></v-text-field>
          </slot>

          <div v-if="!hidePresets" class="d-flex align-center" style="gap: 1rem; flex: 1">
            <PresetManager
              v-if="index"
              ref="presetManager"
              :value="computedOptions"
              :default-item="defaultFilterItem"
              :loading="loadingPresets"
              :saving="savingPreset"
              :default-item-args="{
                index,
              }"
              :load-callback="loadPresets"
              :save-callback="savePresets"
              :remove-callback="removePreset"
              label="Filter presets"
              hide-details="auto"
              outlined
              dense
              clearable
              style="flex: 1; min-width: 15rem"
              @input="onApplyPresetFilters"
            />
          </div>
        </div>
      </v-expand-transition>

      <div class="d-flex align-center" style="gap: 0.5rem">
        <InlineTutorial v-if="help" :slug="help">
          <template #activator="{ on }">
            <MenuTooltip
              v-on="on"
              :btn-attrs="{
              icon: true,
            }"
              icon="mdi-help-circle-outline"
              tooltip="How is this working?"
            />
          </template>
        </InlineTutorial>

        <v-btn
          icon
          @click="onToggleContent"
        >
          <v-icon v-if="isCollapsed">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-expand-transition>
      <div v-if="!isCollapsed">
        <v-card-text>
          <DataTable
            v-bind="attrs"
            :headers="headers"
            :search="search"
            :force-load="forceLoad"
            :items-per-page="itemsPerPage"
            :options.sync="computedOptions"
            @load="items => $emit('load', items)"
          >
            <template v-for="(slot, key) in $scopedSlots" v-slot:[key]="slot">
              <slot :name="key" v-bind="slot" />
            </template>
            <template #header._last>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!hasFilters"
                    outlined
                    x-small
                    @click="onClearFilters"
                  >
                    <v-icon x-small left>mdi-filter-variant-remove</v-icon>
                    <span>Clear</span>
                  </v-btn>
                </template>
                <span v-text="$t('listView.clearFilters')"></span>
              </v-tooltip>
            </template>
          </DataTable>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import {Route} from 'vue-router';
import Identity from '@/modules/sdk/core/identity';
import DatatableStateService from '@/modules/sdk/services/datatable-state.service';
import InlineTutorial from '@/modules/common/components/InlineTutorial.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';

@Component({
  components: {
    DataTable,
    PresetManager,
    InlineTutorial,
    MenuTooltip,
  }
})
export default class ListView extends Vue {
  @Prop() newRoute?: Partial<Route>;
  @Prop() title!: string;
  @Prop() icon!: string;
  @Prop({ type: Boolean, default: true }) searchable!: boolean;
  @Prop({ type: String, default: null }) help!: string;
  @Prop({ type: Boolean, default: false }) hideNewButton!: boolean;
  @Prop({ type: Boolean, default: false }) hidePresets!: boolean;
  @Prop({ type: Boolean, default: false }) collapsed!: boolean;
  @Prop({ default: 25 }) itemsPerPage!: number;
  @Prop() index!: string;
  @Prop() testId!: string;
  @Prop({ default: () => ([]) }) private headers!: Array<any>;
  @PropSync('options', { default: () => ({}) }) syncedOptions!: any;

  isCollapsed = false;
  search = '';
  forceLoad = 0;
  loadingPresets = false;
  savingPreset = false;
  filters: Array<any> = []
  defaultFilterItem: any = {
    page: 1,
    itemsPerPage: 25,
    filters: [],
  }

  innerOptions = {
    page: 1,
    itemsPerPage: 25,
    filters: [],
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: true,
    mustSort: false,
  };

  get computedOptions(): any {
    return Object.assign({}, this.innerOptions, this.syncedOptions);
  }

  set computedOptions(options: any) {
    this.$emit('options', options);
  }

  get _newRoute(): Partial<Route> {
    return this.newRoute || { name: (this.$route.name || '').replace('List', 'New') }
  }

  get attrs(): any {
    return Object.assign({}, {
      footerProps: {
        itemsPerPageOptions: this.itemsPerPage === -1 ? [5, 10, 25, 50, 100, -1] : [5, 10, 25, 50, 100],
      }
    }, this.$attrs);
  }

  get hasFilters(): boolean {
    return this.computedOptions.filters.filter((filter: any) => {
      if (filter.value !== null && !Array.isArray(filter.value)) {
        return true;
      } else if (Array.isArray(filter.value) && filter.value.length > 0 && !filter.value.includes(null)) {
        return true;
      } else if (['is null', 'is not null'].includes(filter.operator)) {
        return true;
      }
      return false;
    }).length > 0
      || this.computedOptions.sortBy.length > 0
      || this.computedOptions.sortDesc.length > 0;
  }

  onToggleContent(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onClearFilters() {
    this.innerOptions.filters = [];
    this.innerOptions.sortBy = [];
    this.innerOptions.sortDesc = [];
  }

  onApplyPresetFilters(preset: any) {
    this.innerOptions = preset;
  }

  loadPresets(): Promise<any> {
    return DatatableStateService.getInstance().getAll({
      filters: [{
        field: 'index',
        operator: 'equals',
        value: this.index,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: any) {
    return DatatableStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: any) {
    return DatatableStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  created() {
    this.isCollapsed = this.collapsed;
    this.defaultFilterItem.itemsPerPage = this.itemsPerPage;
    this.innerOptions.itemsPerPage = this.itemsPerPage;
  }
}
</script>
