<template>
  <v-container fluid class="pa-4">
    <DataForm
      v-test-id="'country'"
      v-model="model"
      :service="service"
      :loading="loading"
      name="country"
      title="Country"
    >
      <template #form="{ formErrors, rules }">
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="model.data.code"
              :error-messages="formErrors.code"
              :rules="[rules.required]"
              outlined
              label="Country Code"
              hide-details="auto"
              clearable
              required
              @input="delete formErrors.code"
            />
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field
              v-model="model.data.label"
              :error-messages="formErrors.label"
              :rules="[rules.required]"
              outlined
              label="Country Name"
              hide-details="auto"
              clearable
              required
              @input="delete formErrors.label"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="model.data.latitude"
              :error-messages="formErrors.latitude"
              :rules="[]"
              outlined
              label="Latitude"
              hide-details="auto"
              clearable
              required
              @input="delete formErrors.latitude"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="model.data.longitude"
              :error-messages="formErrors.longitude"
              :rules="[]"
              outlined
              label="Longitude"
              hide-details="auto"
              clearable
              required
              @input="delete formErrors.longitude"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <GMapsMarkerManager
              v-model="markers"
              class="w-100"
              style="height: 30rem"
              draggable
            />
          </v-col>
        </v-row>
      </template>
    </DataForm>

  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Watch } from 'vue-property-decorator';
import Logger from '@/modules/sdk/core/logger';
import CountryModel from '@/modules/sdk/models/country.model';
import DataForm from '@/modules/common/components/DataForm.vue';
import CountryService from '@/modules/sdk/services/country.service';
import GMapsMarkerManager, { IMarker } from '@/modules/common/components/GMapsMarkerManager.vue';

const d = new Logger('views/Admin/Form/CountryForm');

@Component({
  components: {
    GMapsMarkerManager,
    DataForm,
  }
})
export default class CountryForm extends Vue {
  service = CountryService.getInstance();
  loading = false;
  model: CountryModel = new CountryModel();
  markers: Array<IMarker> = [];

  @Watch('markers', {deep: true})
  onMarkersChange() {
    setTimeout(() => {
      this.model.data.latitude = this.markers[0].position.lat;
      this.model.data.longitude = this.markers[0].position.lng;
    }, 0);
  }

  @Watch('model', {deep: true, immediate: true})
  onModelChange() {
    const lat = parseFloat(this.model.data.latitude);
    const lng = parseFloat(this.model.data.longitude);
    const pos = this.markers[0]?.position;
    if ((lat || lng) && (!pos || (pos.lat !== lat || pos.lng !== lng))) {
      this.markers = [{
        model: this.model,
        position: {
          lat: lat,
          lng: lng,
        },
      }];
    }
  }

  load(id: number) {
    this.loading = true;
    this.service.get({id})
      .then(response => this.model = response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.loading = false);
  }

  created() {
    if (this.$route.params.id) {
      this.load(parseInt(this.$route.params.id));
    }
  }
}
</script>
