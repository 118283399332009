<template>
  <v-container class="pa-4" fluid>
    <h3 class="mt-6">datatable filters</h3>
    <DataTable
      :headers="headers"
    />
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import DashboardBuilder from '@/modules/common/components/DashboardBuilder.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import UserService from '@/modules/sdk/services/user.service';
import UserModel from '@/modules/sdk/models/user.model';
import UserAutocompleteComponent from '@/modules/common/components/UserAutocompleteComponent.vue';

@Component({
  components: {
    UserAutocompleteComponent,
    DataTable,
    DashboardBuilder,
  }
})
export default class DevView extends Vue {
  visualization: VisualizationModel = new VisualizationModel()

  users: Array<UserModel> = [];
  userList: Array<UserModel> = [];

  headers = [
    {
      text: 'Number',
      value: 'number',
      filterable: {
        type: 'number',
      }
    },
    {
      text: 'Range',
      value: 'range',
      filterable: {
        type: 'range',
      }
    },
    {
      text: 'Date',
      value: 'date',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Distinct',
      value: 'distinct',
      filterable: {
        type: 'distinct'
      },
    },
    {
      text: 'Text',
      value: 'text',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Boolean',
      value: 'boolean',
      filterable: {
        type: 'boolean',
        attrs: {
          trueValue: '1',
          falseValue: '0'
        }
      }
    },
    {
      text: 'Enum',
      value: 'enum',
      filterable: {
        type: 'enum',
        items: [
          { text: 'Synthetic', value: 'synthetic' },
          { text: 'Plastic', value: 'plastic' },
          { text: 'Solid', value: 'solid' },
        ]
      }
    },
  ];

  created() {
    UserService.getInstance().getAll()
      .then(response => {
        this.userList = response.data.view.list;
        this.users = response.data.view.list.filter((user: UserModel) => user.data.deleted === 1);
      });
  }
}
</script>
