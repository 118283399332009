const contactTypeList = [
  { value: 'help', text: 'General help' },
  { value: 'bug', text: 'Report a bug' },
  { value: 'comment', text: 'Comment only' },
];

const logicList = [
  { value: 'and', text: 'And' },
  { value: 'or', text: 'Or' },
];

const heatmapLegendsList = [
  { color: 'success', text: 'Fit for purpose' },
  { color: 'warning', text: 'Limited fit for purpose' },
  { color: 'error', text: 'Not fit for purpose' },
];

const operatorList = [
  { value: 'contains', text: 'Contains' },
  { value: 'does not contain', text: 'Does not contain' },
  { value: 'contains word', text: 'Contains word' },
  { value: 'does not contain word', text: 'Does not contain word' },
  { value: 'equals', text: 'Equals' },
  { value: 'does not equal', text: 'Does not equal' },
  { value: 'greater than', text: 'Greater than' },
  { value: 'greater than or equal', text: 'Greater than or equal' },
  { value: 'less than', text: 'Less than' },
  { value: 'less than or equal', text: 'Less than or equal' },
  { value: 'is empty', text: 'Is empty' },
  { value: 'is not empty', text: 'Is not empty' },
  { value: 'regexp', text: 'Matches regular expression' },
  { value: 'not regexp', text: 'Does not match regular expression' },
];

const markerList = [
  { text: 'Circle', value: 'circle', icon: 'mdi-circle-outline', },
  { text: 'Triangle', value: 'triangle', icon: 'mdi-triangle-outline', },
  { text: 'Square', value: 'square', icon: 'mdi-square-outline', },
];

export {
  contactTypeList,
  logicList,
  operatorList,
  markerList,
  heatmapLegendsList,
};
