<template>
  <v-container fluid class="pa-4">
    <router-view></router-view>
    <ListView
      v-test-id="'users'"
      title="Users"
      :headers="headers"
      :service="service"
      index="user"
    >
      <!-- ROLE LIST -->
      <template v-slot:item.rolelist="{item}">
        <v-chip v-for="role of item.data.rolelist" :key="role.data.id" :color="role.data.index | color('role')" :to="{name: 'RoleForm', params: {id: role.data.id}}" class="ma-2" label small>
          {{ $t('role.' + role.data.index) }}
        </v-chip>
      </template>

    </ListView>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component } from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import ListView from '@/modules/common/components/ListView.vue';
import UserService from '@/modules/sdk/services/user.service';
import RoleService from '@/modules/sdk/services/role.service';

@Component({
  components: {
    ListView,
    DataTable
  }
})
export default class UserList extends Vue {
  name = 'UserList';
  service = UserService.getInstance();
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'UserForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Email',
      align: 'start',
      sortable: true,
      value: 'email',
      filterable: true
    },
    {
      text: 'First Name',
      align: 'start',
      sortable: true,
      value: 'firstName',
      filterable: true,
    },
    {
      text: 'Last Name',
      align: 'start',
      sortable: true,
      value: 'lastName',
      filterable: true,
    },
    {
      text: 'Role',
      align: 'start',
      sortable: true,
      value: 'rolelist',
      filterable: {
        field: 'RoleNode.roleId',
        type: 'distinct',
        service: RoleService,
      }
    },
    {
      text: 'Created at',
      align: 'start',
      sortable: true,
      value: 'createdAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Updated at',
      align: 'start',
      sortable: true,
      value: 'updatedAt',
      filterable: {
        type: 'date'
      },
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean',
        labelTrue: 'Deleted',
        labelFalse: 'Active',
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];
}
</script>
