<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    :items="trackerList"
    :loading="loading"
    :disabled="disabled || loading"
    item-text="data.label"
    item-value="data.id"
    @change="onChange"
    @input="input => selected = input"
  >
    <template #append>
      <div v-if="selected !== null" class="d-flex align-center" style="gap: 0.5rem; margin-top: -0.15rem">
        <v-btn
          :loading="downloading"
          :disabled="downloading"
          color="primary"
          small
          outlined
          @mousedown.stop="onDownloadTrackerCSV"
        >
          Download
        </v-btn>
      </div>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Ref, Prop, PropSync } from 'vue-property-decorator';
import TrackerService from '@/services/tracker.service';
import TrackerModel from '@/models/tracker.model';
import Tracker from '@/tracker';
// @ts-ignore
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete/VAutocomplete'
import FileService from '@/modules/sdk/services/file.service';
import { saveAs } from 'file-saver';

@Component
export default class ProjectAutocomplete extends Vue {

  @Ref() readonly projectRef!: VAutocomplete;
  @Prop({ type: Boolean, default: false }) skipLoading!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @PropSync('items', { default: () => ([]) }) trackerList!: Array<TrackerModel>;

  selected: TrackerModel | number | null = null
  loading = false;
  downloading = false;

  onChange(selected: number | TrackerModel) {
    this.$emit('change', selected);
    const tracker = this.trackerList.find(item => item.data.id === (selected instanceof TrackerModel ? selected.data.id : selected));
    if (tracker) {
      new Tracker().loadFile(tracker)
        .then(results => this.$emit('parse', results));
    }
  }

  onDownloadTrackerCSV(): void {
    if (this.selected instanceof TrackerModel) {
      this.downloading = true;
      FileService.getInstance().downloadAsBlob(this.selected.data.datafileentity)
        .then((response: any) => {
          if (this.selected instanceof TrackerModel) {
            saveAs(response.data, this.selected.data.datafileentity.data.name);
          }
        })
        .catch(reason => this.$root.$zemit.handleError(reason))
        .finally(() => this.downloading = false);
    }
  }

  loadTrackers(): Promise<any> {
    this.loading = true;
    return TrackerService.getInstance().getAll()
      .then(response => this.trackerList = response.data.view.list)
      .then(response => this.$emit('load', response))
      .finally(() => this.loading = false);
  }

  created() {
    if (!this.skipLoading) {
      this.loadTrackers();
    }
  }
}
</script>
