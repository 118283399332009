<template>
  <div>
    <h4 class="text--text mb-4">Types of studies:</h4>

    <ListBuilder
      v-model="tracker.data.heatmap.data.columns"
      :default-item="defaultColumn"
      :disabled="disabled"
      :collapsed="!disabled"
      group="heatmap_column"
      sortable
      duplicable
      collapsable
      wrapped
    >
      <template #header="column">
        <v-text-field
          v-model="column.item.name"
          :rules="[rules.required]"
          :disabled="disabled"
          label="Type of study"
          outlined
          dense
          hide-details="auto"
        />
      </template>
      <template #item="column">
        <v-row class="mt-0">
          <v-col cols="12">
            <ListBuilder
              v-model="column.item.items"
              :default-item="defaultItem"
              :disabled="disabled"
              min="1"
              group="heatmap_item"
              sortable
              duplicable
              wrapped="color"
            >
              <template #header>
                <h3 class="text--text">If the following conditions are true:</h3>
              </template>
              <template #item="item">
                <ListBuilder
                  v-model="item.item.queries"
                  :default-item="defaultQuery"
                  :disabled="disabled"
                  class="mt-4"
                  min="1"
                  group="heatmap_query"
                  nested
                  conditional
                  sortable
                >
                  <template #item="query">
                    <v-row class="d-flex align-start flex-wrap" no-gutters style="gap: 0.5rem">

                      <!-- FIELD -->
                      <v-col style="min-width: 10rem">
                        <v-autocomplete
                          v-model="query.item.field"
                          :items="sortedHeaders"
                          :rules="[rules.required]"
                          :disabled="disabled"
                          item-text="text"
                          item-value="text"
                          label="Field"
                          hide-details="auto"
                          height="40"
                          clearable
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>

                      <!-- COMPARATOR -->
                      <v-col style="min-width: 10rem">
                        <v-autocomplete
                          v-model="query.item.operator"
                          :items="operatorList"
                          :rules="[rules.required]"
                          :disabled="disabled"
                          label="Operator"
                          hide-details="auto"
                          placeholder="Comparator"
                          height="40"
                          outlined
                          dense
                          class="text-no-wrap"
                        ></v-autocomplete>
                      </v-col>

                      <!-- VALUE -->
                      <v-col style="min-width: 10rem">
                        <v-text-field
                          v-if="[
                          'is empty', 'is not empty',
                          'regexp', 'not regexp'
                        ].includes(query.item.operator)"
                          v-model="query.item.value"
                          v-bind="getValueAttrs(query.item)"
                          :disabled="isDisabledValue(query.item) || disabled"
                          :type="[].includes(query.item) ? 'number' : 'text'"
                          hide-details="auto"
                          outlined
                          dense
                          clearable
                        />
                        <v-combobox
                          v-else
                          v-model="query.item.value"
                          v-bind="getValueAttrs(query.item)"
                          :disabled="isDisabledValue(query.item) || disabled"
                          label="Value"
                          hide-details="auto"
                          multiple
                          outlined
                          dense
                          clearable
                          chips
                          hide-selected
                          return-object
                        >
                          <template #selection="{ attrs, item, parent, selected }">
                            <v-chip
                              v-bind="attrs"
                              :input-value="selected"
                              :color="item.color"
                              chips
                              clearable
                            >
                            <span class="mr-2">
                              {{ typeof item === 'object' ? item.text : item }}
                            </span>
                              <v-btn icon x-small :disabled="disabled" @click="parent.selectItem(item)">
                                <v-icon small>
                                  $delete
                                </v-icon>
                              </v-btn>
                            </v-chip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>
                  </template>
                </ListBuilder>

                <h5 class="text--text my-4">Set the following color:</h5>

                <ColorInput
                  v-if="!disabled"
                  v-model="item.item.color"
                  :disabled="disabled"
                  :suggestions="[
                    $vuetify.theme.themes.light.success,
                    $vuetify.theme.themes.light.warning,
                    $vuetify.theme.themes.light.error,
                    $vuetify.theme.themes.light.skip,
                  ]"
                  dark
                  block
                  skip-alpha
                >
                  <v-icon left>mdi-format-color-fill</v-icon>
                  <span>Click to set color</span>
                </ColorInput>
                <div v-else class="pa-4" :style="{
                  backgroundColor: item.item.color,
                }"></div>

                <template v-if="item.item.queries.length > 1 || (disabled && item.item.match.check)">

                  <h5 class="text--text my-4">But only if:</h5>
                  <div class="d-flex align-center" style="gap: 1rem">
                    <v-switch
                      v-if="!disabled"
                      v-model="item.item.match.check"
                      :disabled="!canMatch(item.item) || disabled"
                      class="mt-0 pt-0"
                      inset
                      hide-details
                    ></v-switch>

                    <v-text-field
                      v-model.number="item.item.match.amount"
                      :disabled="!item.item.match.check || !canMatch(item.item) || disabled"
                      :min="1"
                      :max="item.item.queries.length"
                      label="Amount"
                      type="number"
                      hide-details="auto"
                      outlined
                      dense
                      clearable
                    />

                    field{{ item.item.match.amount === 1 ? '' : 's' }} out of {{ item.item.queries.length }} {{ item.item.match.amount === 1 ? 'is' : 'are' }}

                    <v-select
                      v-model="item.item.match.value"
                      :disabled="!item.item.match.check || !canMatch(item.item) || disabled"
                      :items="[
                      { text: 'True', value: true },
                      { text: 'False', value: false },
                    ]"
                      label="Value"
                      hide-details="auto"
                      outlined
                      dense
                      clearable
                    />
                  </div>
                </template>
              </template>
            </ListBuilder>
          </v-col>

          <v-col cols="12" class="pt-0">
            <h5 class="text--text mb-4">Otherwise, set this default color:</h5>

            <ColorInput
              v-if="!disabled"
              v-model="column.item.color"
              :disabled="disabled"
              :suggestions="[
                $vuetify.theme.themes.light.success,
                $vuetify.theme.themes.light.warning,
                $vuetify.theme.themes.light.error,
                $vuetify.theme.themes.light.skip,
              ]"
              skip-alpha
              block
            >
              <v-icon left>mdi-format-color-fill</v-icon>
              <span>Click to set color</span>
            </ColorInput>
            <div v-else class="pa-4" :style="{
            backgroundColor: column.item.color,
          }"></div>
          </v-col>
        </v-row>
      </template>
    </ListBuilder>

    <template v-if="legendColors.length > 0">
      <h4 class="text--text mt-8 mb-4">Legends:</h4>

      <v-list>
        <draggable
          v-model="tracker.data.heatmap.data.legends"
          :animation="200"
          :disabled="disabled"
          handle=".sortable-handle"
          ghost-class="background-white"
          group="legend"
          @start="drag = true"
          @end="drag = false"
          @change="onLegendSort"
        >
          <v-list-item
            v-for="legend in tracker.data.heatmap.data.legends"
            :key="legend.color"
          >
            <v-list-item-icon>
              <v-icon v-if="!disabled" class="mt-1 mr-4 sortable-handle">mdi-drag</v-icon>
              <v-icon class="mt-1" :color="legend.color">mdi-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-combobox
                v-model="legend.text"
                :items="legendValues"
                :disabled="disabled"
                placeholder="- Choose a legend -"
                outlined
                dense
                hide-details
              />
            </v-list-item-content>
          </v-list-item>
        </draggable>

      </v-list>
    </template>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator';
import ListBuilder from '@/modules/common/components/ListBuilder.vue';
import ColorInput from '@/modules/common/components/ColorInput.vue';
import TrackerModel from '@/models/tracker.model';
import { IQueryItem } from '@/modules/sdk/core/query';
import { operatorList, heatmapLegendsList } from '@/enums/global';
import Rules from '@/modules/sdk/core/rules';
import Tracker from '@/tracker';
import draggable from 'vuedraggable';
import Utils from '@/modules/sdk/core/utils';

interface IDataHeader {
  category: string | null,
  text: string,
}

interface IDefinition {
  [key: string]: string | null | boolean
}

export interface Item {
  queries: Array<IQueryItem>,
  color: string | null,
  legend: string | null,
  match: {
    check: boolean,
    amount: number,
    value: boolean,
  }
}

export interface Column {
  name: string | null,
  items: Array<Item>,
  color: string | null,
}

export interface ILegendColor {
  color: string | null,
  value: string | null
}

@Component({
  components: {
    draggable,
    ListBuilder,
    ColorInput,
  }
})
export default class HeatmapBuilder extends Vue {

  @VModel({ default: () => new TrackerModel() }) tracker!: TrackerModel
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Array, default: () => ([]) }) definitions!: Array<IDefinition>

  loading = false
  headers: Array<IDataHeader> = []
  sortedHeaders: Array<IDataHeader> = []
  rows: Array<{[key: string]: string}> = []
  operatorList = operatorList
  heatmapLegendsList = heatmapLegendsList
  rules: any = {}
  drag = false

  defaultColumn: Column = {
    name: null,
    items: [],
    color: null,
  };

  defaultItem: Item = {
    queries: [],
    color: null,
    legend: null,
    match: {
      check: false,
      amount: 0,
      value: true,
    },
  }

  defaultQuery: IQueryItem = {
    field: null,
    operator: 'equals',
    logic: null,
    value: null,
    group: [],
    filter: {
      label: null,
      items: [],
    }
  }

  legendSort: Array<ILegendColor> = [];

  @Watch('tracker.data.heatmap.data.columns', { deep: true, immediate: true })
  onColumnChange() {
    this.legendColors.forEach(legend => {
      if (!this.tracker.data.heatmap.data.legends.find((item: any) => item.color === legend.color)) {
        this.tracker.data.heatmap.data.legends.push(legend);
      }
    })
    this.tracker.data.heatmap.data.legends = this.tracker.data.heatmap.data.legends.filter((legend: any) => this.legendColors.find(item => item.color === legend.color))
  }

  get legendValues(): Array<string> {
    return this.heatmapLegendsList.map(item => item.text);
  }

  get legendColors(): Array<ILegendColor> {
    const legends: Array<ILegendColor> = [];
    const injectColor = (color: string) => {
      if (!legends.find(legend => (legend.color && color && legend.color.substring(0, 7) === color.substring(0, 7)) || legend.color === color)) {
        const heatmapLegend = heatmapLegendsList.find(heatmapLegend => (this.$vuetify.theme.themes.light[heatmapLegend.color] || '').toString().toUpperCase() === color);
        legends.push({
          color,
          value: (heatmapLegend || {}).text || null,
        })
      }
    }
    this.tracker.data.heatmap.data.columns.forEach((column: any) => {
      column.items.forEach((item: any) => {
        injectColor(item.color);
      })
      injectColor(column.color);
    })

    // Check if legendSort is not empty and sort legends based on legendSort order
    if (this.legendSort.length > 0) {
      legends.sort((a, b) => {
        const indexA = this.legendSort.findIndex(sortItem => sortItem.color === a.color);
        const indexB = this.legendSort.findIndex(sortItem => sortItem.color === b.color);
        return indexA - indexB;
      });
    }

    return legends;
  }

  set legendColors(legends: Array<ILegendColor>) {
    this.legendSort = legends;
  }

  onLegendSort() {
    this.$emit('legend-sort', this.tracker.data.heatmap.data.legends);
  }

  canMatch(item: Item): boolean {
    return (
      item.queries.slice(1).every(query => query.logic === 'and')
      || item.queries.slice(1).every(query => query.logic === 'or')
    );
  }

  getValueAttrs(item: IQueryItem): any {
    const attrs: any = {
      items: [],
    };
    let countries: Array<string> = [];
    if (!this.isDisabledValue(item)) {
      switch (item.field) {
        case 'Country(ies)':
          countries = []
          this.rows.forEach(row => {
            (row['Country(ies)'] || '').toString().split(',').forEach(country => {
              if (!countries.includes(country)) {
                countries.push(country);
              }
            });
          })
          attrs.items = countries.sort();
          break;
        default:
          const definition: any = this.definitions.find(def => def.name === item.field) || { single: true };
          const items: Array<string | false> = this.rows.map(row => item.field !== null && row[item.field]);
          items.forEach(item => {
            if (item && !definition.single) {
              const subItems = Utils.splitIgnoringCommasInQuotes(item);
              subItems.forEach(subItem => {
                if (!attrs.items.includes(subItem)) {
                  attrs.items.push(subItem);
                }
              })
            } else if (item) {
              attrs.items.push(item);
            }
          })
          attrs.items = attrs.items.sort();
      }
    }
    return attrs;
  }

  isDisabledValue(item: IQueryItem) {
    return (!item.field || item.operator === null || [
      'is empty',
      'is not empty'
    ].indexOf(item.operator) !== -1);
  }

  loadFile() {
    this.loading = true;
    new Tracker().loadFile(this.tracker)
      .then((results: any) => {
        this.headers = results.headers;
        this.rows = results.rows;
        this.sortedHeaders = results.headers.sort((a: any, b: any) => a.text > b.text ? 1 : -1);
        this.loading = false;
      })
  }

  created() {
    if (this.$vuetify.theme.themes.light.skip) {
      this.defaultColumn.color = this.$vuetify.theme.themes.light.skip.toString();
    }
    this.defaultItem.queries.push(structuredClone(this.defaultQuery));
    this.defaultColumn.items.push(structuredClone(this.defaultItem));

    this.rules = {
      required: (value: string) => Rules.required(value) || this.$t('rules.required'),
    };

    this.loadFile();
  }
}
</script>
