import Vue, { VueConstructor } from 'vue';
import moment from 'moment';

export default class Filters {

  static getInstance<T> (this: new () => T, opts: Partial<Filters> = {}): T {
    return Object.assign(new this(), opts);
  }

  nl2br = (text: string | null) => {
    return text?.replace(/(\r\n|\n|\r)/g, '<br>');
  }

  color = (index: string, type?: string) => {
    if (type) {
      index = type + ':' + index;
    }
    switch (index?.toLowerCase()) {
      case 'deleted:0':
        return 'accent accent-1';
      case 'deleted:1':
        return 'red accent-1';

      case 'stage:screening':
        return 'gray accent-1';
      case 'stage:indepth':
        return 'gray accent-2';
      case 'stage:final':
        return 'gray accent-3';

      case 'status:pending':
        return 'warning';
      case 'status:halt':
        return 'error';
      case 'status:pass':
        return 'success';
      case 'status:skip':
        return 'grey';
      case 'status:conflict':
        return 'yellow';

      case 'status:finished':
        return 'success';
      case 'status:canceled':
        return 'warning';
      case 'status:blocked':
        return 'warning';

      case 'type:info':
        return 'info';
      case 'type:notice':
        return 'notice';
      case 'type:warning':
        return 'warning';
      case 'type:success':
        return 'success';
      case 'type:error':
        return 'error';

      case 'trusted:0':
        return 'warning';
      case 'trusted:1':
        return 'success';

      default:
        return 'default';
    }
  };

  date = (value: string, format = 'YYYY-MM-DD') => {
    if (value) {
      return moment(String(value)).format(format);
    }
  };

  parseLinks = (text = '', target = '_blank') => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const emailRegex = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/g;
    return (text || '').replace(urlRegex, '<a href="$1" target="' + target + '">$1</a>')
      .replace(emailRegex, '<a href="mailto:$1" target="' + target + '">$1</a>')
  };

  excerpt = (text = '', limit = -1): string => {
    if (limit !== -1 && text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  init() {
    Vue.filter('nl2br', this.nl2br);
    Vue.filter('color', this.color);
    Vue.filter('date', this.date);
    Vue.filter('excerpt', this.excerpt);
    Vue.filter('parseLinks', this.parseLinks);
  }
}
