import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    // libraries: 'places',
  },
})

export default VueGoogleMaps;
