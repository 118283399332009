<template>
  <v-container class="pa-4 h-100" fluid>

    <portal v-if="showPortalOptions" to="toolbar_right" :order="2">
      <MenuTooltip
        :menu-attrs="{ bottom: true, left: true,  closeOnContentClick: false }"
        :btn-attrs="{ text: true }"
        btn-text="Context"
        chevron-down
      >
        <v-card style="width: 20rem">
          <v-card-text>
            <v-checkbox
              v-model="keepViewState"
              label="Keep view state in memory"
              class="ma-0 pa-0"
              hide-details
            />
<!--            <v-checkbox-->
<!--              v-model="truncateHeaders"-->
<!--              label="Truncate data table headers"-->
<!--              class="ma-0 pa-0"-->
<!--              hide-details-->
<!--            />-->
          </v-card-text>
        </v-card>
      </MenuTooltip>
    </portal>

    <!-- EXPORT PDF OVERLAY -->
    <v-overlay :value="generatingPDF" z-index="1000">
      <div class="text-center">
        <h2>Generating PDF</h2>
        <v-progress-linear style="width: 15rem" class="my-3" indeterminate />
        <p>Please wait...</p>
      </div>
    </v-overlay>

    <v-form v-model="formIsValid" :disabled="searching" class="d-flex flex-column h-100" @submit.prevent="onSubmitForm">

      <!-- QUERY INSPECTOR DIALOG -->
      <ModalDialog
        v-model="queryInspectorDialog.visible"
        :disabled="queryInspectorDialog.saving"
        title="Query Inspector"
        max-width="1400"
        background-color="background"
        scrollable
      >
        <template #body>
          <v-card class="mt-4">
            <v-card-text>
              <HeatmapBuilder
                v-model="queryInspectorDialog.tracker"
                :disabled="queryInspectorDialog.disabled"
              />
            </v-card-text>
          </v-card>
        </template>
        <template #buttons>
          <v-checkbox
            v-model="queryInspectorDialog.disabled"
            hide-details
            label="Disabled"
            class="ma-0 pa-0"
          />
          <v-spacer></v-spacer>
          <v-btn
            :disabled="queryInspectorDialog.disabled || queryInspectorDialog.saving"
            :loading="queryInspectorDialog.saving"
            color="primary"
            @click="onSaveQueryInspector"
          >
            Save
          </v-btn>
          <v-btn
            :disabed="queryInspectorDialog.saving"
            outlined
            @click="queryInspectorDialog.visible = false"
          >
            Close
          </v-btn>
        </template>
      </ModalDialog>

      <!-- COMPARE DIALOG -->
      <ModalDialog
        v-model="compareDialog.visible"
        :title="compareDialog.selectDataSourceFirst
          ? (compareDialog.titlePrefix ? compareDialog.titlePrefix + ' - ' : '') + ('Select data source (' + compareDialog.rows.length + ')')
          : compareDialog.rows.length > 1
            ? 'Compare data sources (' + compareDialog.rows.length + ')'
            : currentViewedItem['Name of Data Source']
        "
        :max-width="compareDialog.selectDataSourceFirst ? 800 : comparedItems.length <= 1 ? 1000 : 1300"
        icon="mdi-compare"
        background-color="background"
        scrollable
      >
        <template v-if="compareDialog.view === 'categorized' && compareDialog.rows.length > 1" #header>
          <div class="px-12 pt-3 pb-6">
            <div class="d-flex align-center" style="gap: 1rem">
              <h3 v-if="compareDialog.rows[compareDialog.indexA]" class="pl-4" :style="{ width: comparedItems.length <= 1 ? '100%' : '50%' }">
                {{ compareDialog.rows[compareDialog.indexA]['Name of Data Source'] }}
              </h3>
              <h3 v-if="compareDialog.rows[compareDialog.indexB]" class="pl-3" :style="{ width: comparedItems.length <= 1 ? '100%' : '50%' } ">
                {{ compareDialog.rows[compareDialog.indexB]['Name of Data Source'] }}
              </h3>
            </div>
          </div>
          <v-divider />
        </template>
        <template #body>

          <!-- SELECT DATA SOURCE -->
          <v-list v-if="compareDialog.selectDataSourceFirst" class="mt-4">
            <v-list-item
              v-for="(row, rowIdx) in compareDialog.rows"
              :key="rowIdx"
              @click="() => onSelectDataSourceClick(rowIdx, row)"
            >
              <v-list-item-icon>
                <v-icon>mdi-book-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="row['Name of Data Source']"></v-list-item-title>
                <v-list-item-subtitle v-text="row['Type of Data Source']"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <template v-else-if="compareDialog.view === 'categorized'">
            <v-expansion-panels v-model="compareDialog.panels" multiple class="mt-4" light>
              <v-expansion-panel
                v-for="(category, categoryIdx) in categories"
                :key="categoryIdx"
                :style="{
                  backgroundColor: categoryIdx > 0 ? '#' + colors[categoryIdx - 1] : null,
                }"
              >
                <v-expansion-panel-header>
                  <span v-text="category.text || 'Uncategorized'"></span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="d-flex align-start" style="column-gap: 1rem">
                    <div
                      v-for="(row, rowIdx) in comparedItems"
                      :key="row['Name of Data Source'] + rowIdx"
                      class="h-100"
                      :style="{
                        width: comparedItems.length > 1 ? '50%' : '100%',
                        minWidth: comparedItems.length > 1 ? '50%' : '100%',
                        maxWidth: comparedItems.length > 1 ? '50%' : '100%',
                        position: 'relative',
                      }"
                    >
                      <v-divider v-if="rowIdx === 0 && comparedItems.length > 1" vertical style="height: 100%; width: 1px; position: absolute; right: 0" />
                      <v-list color="transparent">
                        <v-list-item
                          v-show="!compareDialog.hideSimilarities || ((compareDialog.rows[compareDialog.indexA] && compareDialog.rows[compareDialog.indexA][header.data.text]) !== (compareDialog.rows[compareDialog.indexB] && compareDialog.rows[compareDialog.indexB][header.data.text]))"
                          v-for="(header, headerIdx) in category.headers.filter(header => header.data.text !== 'Name of Data Source')"
                          :key="headerIdx"
                        >
                          <v-list-item-content>
                            <v-list-item-title v-text="header.data.text"></v-list-item-title>
                            <v-list-item-subtitle class="text-wrap">
                              <TrackerViewCell
                                :definitions="definitions"
                                :abbreviations="abbreviations"
                                :firstValue="compareDialog.rows[compareDialog.indexA] && compareDialog.rows[compareDialog.indexA][header.data.text]"
                                :secondValue="compareDialog.rows[compareDialog.indexB] && compareDialog.rows[compareDialog.indexB][header.data.text]"
                                :header="header.data"
                                :row="row"
                                :show-diff="compareDialog.showDiff && rowIdx !== 0"
                              />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>
          <template v-else-if="compareDialog.view === 'tabular'">
            <v-card>
              <v-data-table
                v-model="compareDialog.singleSelect"
                v-fixed-columns
                :headers="getComparedHeaders(compareDialog.rows)"
                :items="pivotedTabularRows"
                :fixed-header="true"
                :show-select="!isPivoted && compareDialog.showDiff"
                :single-select="compareDialog.showDiff"
                :items-per-page="isPivoted ? -1 : 10"
                :hide-default-footer="isPivoted"
                :hide-default-header="isPivoted && comparedItems.length <= 1"
                :class="['mt-4 dt-compare', {
                  pivoted: isPivoted
                }]"
                item-key="Name of Data Source"
                multi-sort
              >
                <template v-slot:[header.slot]="{ item }" v-for="(header, headerIdx) in headersSlot">
                  <TrackerViewCell
                    v-model="item[header.text]"
                    :key="headerIdx"
                    :definitions="definitions"
                    :abbreviations="abbreviations"
                    :firstValue="getSelectedItemToCompare(item, header)"
                    :secondValue="item[header.text]"
                    :header="isPivoted ? { text: item['Name of Data Source'] } : header"
                    :row="item"
                    :show-diff="compareDialog.showDiff"
                    :style="!header ? 'color: var(--v-primary-base); font-weight: bold' : ''"
                  />
                </template>
                <template v-slot:[cell.slot]="{ item }" v-for="(cell, cellIdx) in isPivoted ? [{ slot: 'item.Name of Data Source'}] : []">
                  <span :key="cellIdx" class="font-weight-bold">{{ item['Name of Data Source'] }}</span>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </template>
        <template #close.prepend>

          <div v-if="compareDialog.showDiff" class="d-flex align-center text-no-wrap mr-6" style="gap: 0.5rem">
            <strong>Legend:</strong>
            <v-chip label small color="success">Additional</v-chip>
            <v-chip label small color="warning">Different</v-chip>
            <v-chip label small color="error">Missing</v-chip>
          </div>

          <v-sheet v-if="!compareDialog.selectDataSourceFirst" class="d-flex align-center" style="gap: 1rem">
            <v-btn-toggle v-model="compareDialog.view" color="primary" tile group>
              <v-btn value="tabular" small>
                <v-icon left>mdi-table</v-icon>
                <span>Tabular</span>
              </v-btn>
              <v-btn value="categorized" small>
                <v-icon left>mdi-table-column</v-icon>
                <span>Categorized</span>
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
        </template>
        <template #footer v-if="compareDialog.view === 'categorized' && comparedItems.length > 1">
          <v-sheet class="d-flex align-center pa-4 w-100" color="primary" style="flex: 1" dark>
            <div class="d-flex align-center px-12" style="width: 50%; gap: 0.5rem">
              <v-btn :disabled="compareDialog.indexA === 0" outlined @click="() => compareDialog.indexA--">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <div class="px-4">
                <span v-text="compareDialog.indexA + 1"></span>
                of
                <span v-text="compareDialog.rows.length"></span>
              </div>

              <v-btn :disabled="compareDialog.indexA === compareDialog.rows.length - 1" outlined @click="() => compareDialog.indexA++">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>

            <div class="d-flex align-center px-4" style="width: 50%; gap: 0.5rem">
              <v-btn :disabled="compareDialog.indexB === 0" outlined @click="() => compareDialog.indexB--">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <div class="px-4">
                <span v-text="compareDialog.indexB + 1"></span>
                of
                <span v-text="compareDialog.rows.length"></span>
              </div>

              <v-btn :disabled="compareDialog.indexB === compareDialog.rows.length - 1" outlined @click="() => compareDialog.indexB++">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </v-sheet>
        </template>
        <template #buttons>
          <template>
            <div class="d-flex align-center" style="gap: 1rem">
              <ExportRowsToMenu
                :value="getExportToData(compareDialog.rows, selectedTracker?.data.label)"
                :title="compareDialog.rows.length > 1 ? (compareDialog.titlePrefix ? compareDialog.titlePrefix + ' - ' : '') + selectedTracker?.data.label : currentViewedItem['Name of Data Source']"
                :disabled="!canExportTo"
                outlined
                @generating="value => generatingPDF = value"
              />
              <template v-if="!compareDialog.selectDataSourceFirst">
                <v-select
                  v-model="compareDialog.selectedColumns"
                  :items="dtHeaderFilters"
                  :label="isPivoted ? 'Row(s)' : 'Column(s)'"
                  :disabled="compareDialog.view !== 'tabular'"
                  style="width: 20rem"
                  clearable
                  multiple
                  outlined
                  hide-details
                  dense
                >
                  <template #selection="{ index }">
                    <span v-if="index === 0" v-text="$tc('rwdm.selectedColumnsTotal', compareDialog.selectedColumns.length, {
                      total: compareDialog.selectedColumns.length
                    })"></span>
                  </template>
                </v-select>
                <template v-if="compareDialog.rows.length > 1">
                  <v-checkbox
                    v-model="compareDialog.showDiff"
                    :label="$t('rwdm.showDiff')"
                    class="pa-0 mt-0"
                    hide-details
                  />
                  <v-checkbox
                    v-model="compareDialog.hideSimilarities"
                    :label="$t('rwdm.hideSimilarities')"
                    class="pa-0 mt-0"
                    hide-details
                  />
                </template>
              </template>
            </div>

            <v-spacer />

            <v-btn
              v-if="compareDialog.view === 'tabular'"
              outlined
              color="primary"
              @click="onPivotTable"
            >
              <v-icon left>mdi-rotate-orbit</v-icon>
              <span>Pivot</span>
            </v-btn>
            <v-btn
              v-if="compareDialog.view === 'categorized' && comparedItems.length > 1"
              outlined
              color="primary"
              @click="onFlipIndexes"
            >
              <v-icon left>mdi-flip-horizontal</v-icon>
              <span v-text="$t('btn.flip')"></span>
            </v-btn>
          </template>
          <v-btn
            outlined
            @click="compareDialog.visible = false"
          >
            <span v-text="$t('btn.close')"></span>
          </v-btn>
        </template>
      </ModalDialog>

      <!-- FILE SELECTOR -->
      <v-card style="flex: 0">
        <v-card-title class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-icon left>mdi-database</v-icon>
            <span>Project Selector</span>
          </div>
          <TrackerAutocomplete
            v-model="selectedTracker"
            :items.sync="trackerList"
            label="Select"
            ref="trackerAutocomplete"
            outlined
            dense
            hide-details
            show-download
            return-object
            clearable
            style="max-width: 20rem"
            @change="onChangeTracker"
            @load="onLoadTrackers"
            @parse="onParseTrackers"
          />
        </v-card-title>
      </v-card>

      <!-- QUERY BUILDER -->
      <v-expand-transition style="flex: 0">
        <v-card v-if="selectedTracker" class="mt-3">

          <!-- HEADER -->
          <v-card-title>
            <div class="d-flex align-center flex-wrap justify-space-between w-100" style="gap: 1rem">
              <div class="text-truncate">
                <v-icon left>mdi-magnify</v-icon>
                <span>Advanced Search Builder</span>
              </div>
              <div class="d-flex align-center" style="gap: 0.5rem">
                <PresetManager
                  ref="presetManager"
                  :value="filtersTmp"
                  :default-item="[defaultFilterItem]"
                  :loading="loadingPresets"
                  :saving="savingPreset"
                  :default-item-args="{
                    trackerId: selectedTracker?.data.id,
                  }"
                  :load-callback="loadPresets"
                  :save-callback="savePresets"
                  :remove-callback="removePreset"
                  id="tracker_filters"
                  label="Presets"
                  hide-details="auto"
                  outlined
                  dense
                  clearable
                  style="max-width: 35rem"
                  class="mr-4"
                  @input="onApplyPresetFilters"
                />
                <v-btn type="submit" :disabled="!canSearch" :loading="searching" color="primary" class="px-4">
                  <span v-text="$t('btn.search')"></span>
                </v-btn>
                <v-btn text :disabled="!canReset" @click="onResetClick">
                  <span v-text="$t('btn.reset')"></span>
                </v-btn>
              </div>
            </div>
          </v-card-title>

          <!-- CONTENT -->
          <v-card-text>
            <TrackerQueryBuilder
              v-model="filtersTmp"
              :filter-list="filterList"
              :studies="selectedTracker.data.heatmap.data.columns"
              :legends="computedData.legends"
              @input="onTrackerQueryBuilderChange"
            />
          </v-card-text>
        </v-card>
      </v-expand-transition>

      <!-- CONTENT -->
      <v-expand-transition style="flex: 1">
        <v-card v-if="selectedTracker" class="mt-3 d-flex flex-column">

          <!-- TABS -->
          <v-tabs v-model="tab" color="primary" style="flex: 0" show-arrows grow>
            <v-tab>
              <v-icon left>mdi-format-list-bulleted-square</v-icon>
              <span>List</span>
              <v-badge
                v-if="computedData.finalFilteredRows.length > 0"
                :content="computedData.finalFilteredRows.length"
                inline
                tile
                class="ml-2"
              />
            </v-tab>
            <v-tab>
              <v-icon left>mdi-table</v-icon>
              <span>Data Table</span>
            </v-tab>
            <v-tab :disabled="dashboards.length === 0 || loadingDashboards">
              <v-progress-circular v-if="loadingDashboards" indeterminate size="16" width="2" class="mr-3" />
              <v-icon v-else left>mdi-chart-bar-stacked</v-icon>
              <span>Visualization</span>
            </v-tab>
            <v-tab>
              <v-icon left>mdi-map</v-icon>
              <span>Map</span>
            </v-tab>
            <v-tab :disabled="selectedTracker.data.heatmap.data.columns.length === 0">
              <v-icon left>mdi-coolant-temperature</v-icon>
              <span>Heatmap</span>
            </v-tab>
          </v-tabs>

          <!-- TABS: CONTENT -->
          <v-tabs-items ref="tabs" v-model="tab" :style="['flex: 1', { minHeight: (beforeTabHeight - 60) + 'px' }]" class="h-100 flex-inner-100">

            <!-- LIST -->
            <v-tab-item>
              <div v-if="tabName === 'list'" class="d-flex flex-column h-100" :style="{ height: beforeTabHeight + 'px' }">
                <div class="pa-3 d-flex align-center justify-space-between" style="flex: 0; gap: 1rem">
                  <v-text-field
                    :disabled="showLoading"
                    label="Filter list"
                    placeholder="Type your search terms..."
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    hide-details
                    clearable
                    dense
                    @input="onQueryListChange"
                  />
                  <v-btn-toggle
                    v-model="sorting"
                    dense
                    color="primary"
                  >
                    <v-btn value="asc">
                      <v-icon>mdi-sort-ascending</v-icon>
                    </v-btn>
                    <v-btn value="desc">
                      <v-icon>mdi-sort-descending</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                  <ExportRowsToMenu
                    :value="getExportToData(finalFilteredByQueryListRows, selectedTracker.data.label)"
                    :title="selectedTracker.data.label"
                    :disabled="!canExportTo"
                    outlined
                    @generating="value => generatingPDF = value"
                  />
                </div>

                <v-overlay v-if="showLoading" v-model="showLoading" color="white" absolute>
                  <v-progress-circular color="primary" size="128" indeterminate/>
                </v-overlay>
                <v-alert v-else-if="finalFilteredByQueryListRows.length === 0" type="info" text class="mx-3">
                  No data found
                </v-alert>
                <v-list style="overflow: auto; height: 0; flex-grow: 1">
                  <v-list-item v-for="(row, rowIdx) in finalFilteredByQueryListRows" :key="rowIdx" @click="() => onListItemClick(row)">
                    <v-list-item-icon>
                      <v-icon>mdi-book-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="row['Name of Data Source']"></v-list-item-title>
                      <v-list-item-subtitle v-text="row['Type of Data Source']"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <InfoSourceActivator
                        v-if="row['Info Source']"
                        :value="row['Info Source']"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
            </v-tab-item>

            <!-- DATA TABLE -->
            <v-tab-item>
              <div v-if="tabName === 'datatable'" class="d-flex flex-column h-100">

                <v-overlay v-if="loadingDatabaseMaxHeader" v-model="loadingDatabaseMaxHeader" color="white" absolute>
                  <v-progress-circular color="primary" size="128" indeterminate/>
                </v-overlay>

                <v-data-table
                  v-model="selectedRows"
                  v-fixed-columns
                  :headers="computedData.dtHeaders"
                  :items="computedData.finalFilteredRows"
                  :loading="showLoading"
                  :fixed-header="true"
                  :height="beforeTabHeight - 59"
                  ref="datatable"
                  item-key="Name of Data Source"
                  show-select
                  multi-sort
                >
                  <template v-slot:[th.slot]="{ header }" v-for="(th, thIdx) in thSlot">
                    <v-tooltip v-if="header.text.length !== header.excerpt.length" :key="thIdx" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ header.excerpt }}
                        </span>
                      </template>
                      <span>{{ header.text }}</span>
                    </v-tooltip>
                    <span v-else :key="thIdx">{{ header.text }}</span>
                  </template>
                  <template #footer.prepend>
                    <div class="d-flex align-center" style="gap: 0.5rem">
                      <v-btn color="primary" small :disabled="!canCompare" @click="onCompareClick">
                        <v-icon small left>mdi-compare</v-icon>
                        <span v-text="$t('btn.compare')"></span>
                      </v-btn>
                      <ExportRowsToMenu
                        :value="getExportToData(selectedRows, selectedRows.length === 1 ? selectedRows[0]['Name of Data Source'] : selectedTracker.data.label)"
                        :title="selectedRows.length === 1 ? selectedRows[0]['Name of Data Source'] : selectedTracker.data.label"
                        :disabled="!canDatatableExportTo"
                        outlined
                        small
                        @generating="value => generatingPDF = value"
                      />
                      <div class="ml-4" v-html="$options.filters.nl2br(selectedTracker.data.footerNotes)">
                      </div>
                    </div>
                  </template>
                  <template v-for="(header, headerIdx) in parsedHeaders" #[header.slot]="{ item }">
                    <div v-if="header.openMetadata" :key="header.slot" class="py-3">
                      <div class="mb-2">{{ item[header.value] }}</div>
                      <div class="d-flex align-center" style="gap: 0.5rem">
                        <v-btn
                          outlined
                          x-small
                          @click="() => onListItemClick(item)"
                        >
                          View Metadata
                        </v-btn>
                        <InfoSourceActivator
                          v-if="item['Info Source']"
                          :value="item['Info Source']"
                        />
                      </div>
                    </div>
                    <TrackerViewCell
                      v-else
                      :key="header.slot"
                      :value="item[header.value]"
                      :header="{ text: header.value }"
                      :row="item"
                      :definitions="definitions"
                      :abbreviations="abbreviations"
                      :visible="headerIdx <= datatableMaxHeader"
                    />
                  </template>
                </v-data-table>
              </div>
            </v-tab-item>

            <!-- VISUALIZATION -->
            <v-tab-item>
              <template v-if="tabName === 'visualization'">
                <div class="pt-3 px-4 d-flex align-center justify-space-between" style="z-index: 1; gap: 1rem">
                  <v-chip-group
                    v-model="selectedDashboardIdx"
                    active-class="primary--text text--accent-4 primary"
                    mandatory
                  >
                    <v-chip
                      v-for="(dashboard, dashboardIdx) in dashboards.filter(dashboard => dashboard.data.id)"
                      :value="dashboardIdx"
                      :key="dashboard.autoIncrementId"
                      outlined
                    >
                      {{ dashboard.originalData.title }}
                      <v-icon v-if="userId !== dashboard.data.userId" small right>mdi-lock</v-icon>
                    </v-chip>
                    <v-chip
                      :value="dashboards.filter(dashboard => dashboard.data.id).length"
                      :key="-1"
                      outlined
                      @click="onAddDashboardClick"
                    >
                      <v-icon color="primary">mdi-plus</v-icon>
                    </v-chip>
                  </v-chip-group>

                  <div>
                    <v-btn
                      :disabled="!canDuplicate"
                      color="primary"
                      block
                      small
                      @click="onDuplicateDashboard"
                    >
                      <v-icon small left>mdi-content-copy</v-icon>
                      Duplicate
                    </v-btn>
                  </div>
                </div>

                <TrackerDashboardBuilderForm
                  v-model="dashboard"
                  :items="dashboards[selectedDashboardIdx] && [dashboards[selectedDashboardIdx]] || []"
                  :tracker="selectedTracker"
                  :filter-list="computedData.finalFilters"
                  :data="computedData.finalFilteredRows"
                  :user-id="userId"
                  :loading="!parsed || loadingDashboards"
                  :dashboard-attrs="{ flat: true, tile: true }"
                  id="tracker_view_dashboard_builder"
                  class="h-100"
                  hide-collapsable
                  @add="onAddDashboard"
                  @delete="onDeleteDashboard"
                  @remove="onRemoveDashboard"
                  @on-show-data="onShowData"
                />
              </template>
            </v-tab-item>

            <!-- MAP -->
            <v-tab-item>
              <template v-if="tabName === 'map'">
                <v-overlay v-if="showLoading" v-model="showLoading" color="white" absolute>
                  <v-progress-circular color="primary" size="128" indeterminate/>
                </v-overlay>
                <div v-else :style="{ height: beforeTabHeight + 'px' }">
                  <GMapsMarkerManager
                    v-model="markers"
                    :loading="loading"
                    :disabled="loading"
                    show-label
                    show-title
                    class="w-100 h-100"
                    @click="onClickMarker"
                  />
                </div>
              </template>
            </v-tab-item>

            <!-- HEATMAP -->
            <v-tab-item>
              <template v-if="tabName === 'heatmap'">
                <div v-if="!showLoading && computedData.filteredRows.length === 0" class="h-100 w-100 pa-4">
                  <div class="w-100 pa-12 text-center h-100 d-flex align-center justify-center text-center" style="border: rgba(0, 0, 0, 0.2) dashed 3px; border-radius: 0.5rem">
                    No data available
                  </div>
                </div>
                <v-data-table
                  v-else
                  :items-per-page="-1"
                  :items="computedData.finalFilteredRows"
                  :headers="computedData.heatmapHeaders"
                  :sort-by.sync="heatmapSortBy"
                  :sort-desc.sync="heatmapSortDesc"
                  :height="beforeTabHeight - 59"
                  :loading="showLoading"
                  fixed-header
                >
                  <template #item="{ item }">
                    <tr>
                      <td class="py-3">
                        <div class="mb-2">{{ item['Name of Data Source'] }}</div>
                        <div class="d-flex align-center" style="gap: 0.5rem">
                          <v-btn
                            outlined
                            x-small
                            @click="() => onListItemClick(item)"
                          >
                            View Metadata
                          </v-btn>
                          <InfoSourceActivator
                            v-if="item['Info Source']"
                            :value="item['Info Source']"
                          />
                        </div>
                      </td>
                      <td
                        v-for="(column, columnIdx) in selectedTracker.data.heatmap.data.columns"
                        v-bind="getHeatmapColumnAttrs(column, item)"
                        v-on="canClickHeatmapCell ? {
                          click: () => onClickHeatmapCell(column, columnIdx)
                        } : null"
                        v-ripple
                        :key="column.name"
                      ></td>
                    </tr>
                  </template>
                  <template #footer.prepend>
                    <div class="px-3 d-flex align-center" style="gap: 1rem">
                      <h5 class="text--text">Legend:</h5>
                      <v-chip-group>
                        <v-chip
                          v-for="legend in computedData.legends"
                          :key="legend.color"
                          :color="legend.color"
                          :dark="legend.dark"
                          small
                          style="pointer-events: none"
                        >
                          {{ legend.text }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                  </template>
                </v-data-table>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-expand-transition>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref, Watch} from 'vue-property-decorator';
import GMapsMarkerManager, {IMarker} from '@/modules/common/components/GMapsMarkerManager.vue';
import ExportRowsToMenu, { IExportToData, IExportToItem } from '@/components/ExportRowsToMenu.vue';
import DashboardBuilder from '@/modules/common/components/DashboardBuilder.vue';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue';
import TrackerAutocomplete from '@/components/TrackerAutocomplete.vue';
import VisualizationModel from '@/modules/sdk/models/visualization.model';
import CountryModel from '@/modules/sdk/models/country.model';
import CountryService from '@/modules/sdk/services/country.service';
import ListBuilder from '@/modules/common/components/ListBuilder.vue';
import PresetManager from '@/modules/common/components/PresetManager.vue';
import TrackerDashboardBuilderForm from '@/views/Admin/Form/TrackerDashboardBuilderForm.vue';
import { heatmapLegendsList } from '@/enums/global';
import TrackerModel from '@/models/tracker.model';
import TrackerFilterStateService from '@/services/tracker-filter-state.service';
import Identity from '@/modules/sdk/core/identity';
import HeatmapBuilder, { Column } from '@/components/HeatmapBuilder.vue';
import Query, { IFilter, IPresetFilter, IQueryItem } from '@/modules/sdk/core/query';
import TrackerQueryBuilder from '@/components/TrackerQueryBuilder.vue';
import DiffChipGroup from '@/components/DiffChipGroup.vue';
import InfoSourceActivator from '@/components/InfoSourceActivator.vue';
import TrackerViewCell from '@/components/TrackerViewCell.vue';
import VisualizationService from '@/services/visualization.service';
import tinycolor from 'tinycolor2';
import { IDataHeader, IDefinition, ILegend, ICategory, IAbbreviation } from '@/interfaces';
import TrackerService from '@/services/tracker.service';

let queryListTimeout: number;
let scrollDatatableTimeout: number;
let skipOnSelectedDashboardIdxChange = false;

@Component({
  components: {
    TrackerQueryBuilder,
    DashboardBuilder,
    GMapsMarkerManager,
    ListBuilder,
    ModalDialog,
    PresetManager,
    TrackerAutocomplete,
    HeatmapBuilder,
    TrackerDashboardBuilderForm,
    MenuTooltip,
    DiffChipGroup,
    TrackerViewCell,
    InfoSourceActivator,
    ExportRowsToMenu,
  }
})
export default class TrackerView extends Vue {
  @Ref() readonly presetManager!: PresetManager;
  @Ref() readonly trackerAutocomplete!: TrackerAutocomplete;

  selectedTracker: TrackerModel | null = null;
  trackerList: Array<TrackerModel> = [];
  keepViewState = false

  colors = [
    'bfbfbf',
    'e6b8b7',
    'b8cce4',
    'd8e4bc',
    'fff2cc',
    'f7d7e9',
    'e2f0d9',
    'b7dee8',
    'd8e4bc',
    'e4dfec',
    'fde9d9',
    'daeef3',
  ]

  tab = 0
  tabs = ['list', 'datatable', 'visualization', 'map', 'heatmap']
  selectedDashboardIdx = 0
  datatableMaxHeader = 0;
  loadingDatabaseMaxHeader = false;

  queryInspectorDialog: {
    visible: boolean,
    disabled: boolean,
    saving: boolean,
    columnIdx: number,
    tracker: TrackerModel,
  } = {
    visible: false,
    disabled: true,
    saving: false,
    columnIdx: -1,
    tracker: new TrackerModel(),
  }

  compareDialog: {
    visible: boolean,
    panels: Array<number>,
    rows: Array<{[key: string]: string}>,
    selectedColumns: Array<string>,
    indexA: number,
    indexB: number,
    view: string,
    hideSimilarities: boolean,
    showDiff: boolean,
    pivot: boolean,
    singleSelect: Array<any>,
    selectDataSourceFirst: boolean,
    titlePrefix: string | null,
  } = {
    visible: false,
    panels: [],
    rows: [],
    selectedColumns: [],
    indexA: 0,
    indexB: 0,
    view: 'tabular',
    hideSimilarities: false,
    showDiff: false,
    pivot: true,
    singleSelect: [],
    selectDataSourceFirst: false,
    titlePrefix: null,
  }

  beforeTabHeight = 400;
  sorting: 'asc' | 'desc' | null = null;
  loadingPresets = false;
  savingPreset = false;
  parsed = false;
  selectedRows: Array<{[key: string]: string}> = []
  queryList: string | null = null
  formIsValid = false
  userId: number = Identity.identity?.user.id
  lastFilters: Array<IQueryItem> = []

  filtersTmp: Array<IQueryItem> = []
  presetFilter: IPresetFilter | null = null;
  defaultFilterItem: IQueryItem = {
    field: null,
    operator: 'contains',
    logic: 'and',
    value: null,
    group: [],
    filter: {
      label: null,
      items: [],
    },
  }

  filterList: Array<IFilter> = []
  originalFilterList: Array<IFilter> = []
  countryList: Array<CountryModel> = []
  loading = false
  loadingDashboards = false
  searching = false
  truncateHeaders = true
  headers: Array<IDataHeader> = []
  definitions: Array<IDefinition> = []
  abbreviations: Array<IAbbreviation> = []
  parsedHeaders: Array<any> = []
  rows: Array<{[key: string]: string}> = []
  dashboards: Array<VisualizationModel> = []
  dashboard: VisualizationModel = new VisualizationModel({
    title: 'Tracker Visualization',
    graphs: [],
  })

  csvCountryMapping: { [key: string]: string } = {
    // 'us': 'United States',
    // 'uk': 'United Kingdom',
    // 'the netherlands': 'Netherlands',
    // 'south korea': 'Korea, Republic of',
    // 'south arabia': 'Saudi Arabia',
    // 'iran': 'Iran, Islamic Republic Of',
    // 'taiwan': 'Taiwan, Province of China',
    'multinational (but mostly us)': 'multinational',
    'multinational (24 countries)': 'multinational',
    '60 countries': 'multinational',
  }

  generatingPDF = false
  heatmapSortBy = ['Name of Data Source'];
  heatmapSortDesc = [false];

  computedData: {
    finalFilters: Array<IQueryItem>,
    filteredRows: Array<{[key: string]: string}>,
    filteredHeatmapRows: Array<{[key: string]: string}>,
    finalFilteredRows: Array<{[key: string]: string}>,
    finalFilteredByQueryListRows: Array<{[key: string]: string}>,
    dtHeaders: Array<any>,
    dtPivotedHeaders: Array<any>,
    dtHeadersCompare: Array<any>,
    pivotedTabularRows: Array<any>,
    legends: Array<ILegend>,
    heatmapHeaders: Array<{[key: string]: string}>,
  } = {
    finalFilters: [],
    filteredRows: [],
    filteredHeatmapRows: [],
    finalFilteredRows: [],
    finalFilteredByQueryListRows: [],
    dtHeaders: [],
    dtPivotedHeaders: [],
    dtHeadersCompare: [],
    pivotedTabularRows: [],
    legends: [],
    heatmapHeaders: [],
  }

  @Watch('tab')
  onTabChange() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.beforeTabHeight = this.getBeforeTabHeight();
    });

    this.updateEvents();
  }

  @Watch('selectedDashboardIdx')
  onSelectedDashboardIdxChange(newVal: number, oldVal: number) {
    if (skipOnSelectedDashboardIdxChange) {
      return;
    }
    if (this.dashboards[oldVal] && oldVal !== newVal) {
      if (this.dashboards[oldVal].isDifferentFromOriginal()) {
        this.$root.$shouldTakeAction.ask(
          this.$i18n.t('dataForm.beforeLeaveRoute.title'),
          this.$i18n.t('dataForm.beforeLeaveRoute.body'),
          'Continue',
          'warning',
          true
        )
          .then(response => {
            if (response) {
              if (this.dashboards[oldVal].data.id) {
                this.dashboards[oldVal].revertData();
              } else {
                this.dashboards.splice(oldVal, 1);
              }
              this.selectedDashboardIdx = newVal;
            }
          })
        this.$nextTick(() => {
          skipOnSelectedDashboardIdxChange = true;
          this.selectedDashboardIdx = oldVal;
          setTimeout(() => {
            skipOnSelectedDashboardIdxChange = false;
          })
        })
      }
    }
    setTimeout(() => {
      this.beforeTabHeight = this.getBeforeTabHeight();
    })
  }

  @Watch('filtersTmp', { deep: true })
  onFiltersTmpChange() {
    setTimeout(() => {
      this.beforeTabHeight = this.getBeforeTabHeight();
    }, 100);
  }

  @Watch('tab')
  @Watch('selectedDashboardIdx')
  @Watch('filtersTmp', { deep: true })
  @Watch('selectedTracker', { deep: true })
  @Watch('selectedRows', { deep: true })
  onSelectedTrackerChange() {
    this.saveViewState();
  }

  @Watch('keepViewState')
  onKeepViewStateChanged(state: boolean) {
    localStorage.setItem('tracker_keep_view_state', JSON.stringify(state));
  }

  @Watch('truncateHeaders')
  onKeepTruncateHeadersChanged(state: boolean) {
    localStorage.setItem('tracker_truncate_headers', JSON.stringify(state));
  }

  onSaveQueryInspector() {
    this.queryInspectorDialog.saving = true;

    const heatmap = this.queryInspectorDialog.tracker.data.heatmap;
    const clonedColumns = structuredClone(this.selectedTracker?.data.heatmap.data.columns);
    const modifiedColumn = heatmap.data.columns[0];
    heatmap.data.columns = clonedColumns;
    heatmap.data.columns[this.queryInspectorDialog.columnIdx] = modifiedColumn;

    TrackerService.getInstance().save(this.queryInspectorDialog.tracker)
      .then(response => {
        this.selectedTracker?.assign(response.data.view.single);
        this.queryInspectorDialog.visible = false;
        this.onSubmitForm();
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.queryInspectorDialog.saving = false);
  }

  onQueryListChange(value: string) {
    clearTimeout(queryListTimeout);
    queryListTimeout = setTimeout(() => {
      this.queryList = value;
    }, 500);
  }

  get tabName(): string {
    return this.tabs[this.tab];
  }

  splitValues(value: string): Array<string> {
    if (value.includes('"')) {
      try {
        return JSON.parse('[' + value + ']');
      } catch {
        return [value];
      }
    }
    return value.split(',');
  }

  onApplyPresetFilters(preset: any) {
    this.filtersTmp = preset;
    this.onSubmitForm();
  }

  onAddDashboardClick(): void {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id);
    this.dashboards.push(new VisualizationModel({
      trackerId: this.selectedTracker?.data.id,
      userId: this.userId,
    }))
    this.selectedDashboardIdx = this.dashboards.length - 1;
  }

  @Watch('compareDialog.visible')
  @Watch('compareDialog.view')
  onCompareDialogChange() {
    setTimeout(() => {
      this.computedData.pivotedTabularRows = this.pivotedTabularRows;
      this.computedData.dtHeadersCompare = this.dtHeadersCompare;
      this.computedData.dtPivotedHeaders = this.dtPivotedHeaders;
    })
  }

  onCompareClick(): void {
    Object.assign(this.compareDialog, {
      visible: true,
      panels: [],
      selectedColumns: [],
      rows: this.selectedRows,
      indexA: 0,
      indexB: 1,
      view: 'tabular',
      hideSimilarities: false,
      showDiff: false,
      pivot: true,
      singleSelect: [this.selectedRows[0]],
    });
  }

  onTrackerQueryBuilderChange() {

  }

  getSelectedItemToCompare(row: any, header: any): any {
    return this.isPivoted
      ? row[this.getComparedHeaders(this.compareDialog.rows)[1].value]
      : this.compareDialog.singleSelect[0][header.value];
  }

  get showPortalOptions(): boolean {
    return Identity.hasRole(['admin', 'dev']);
  }

  get canClickHeatmapCell(): boolean {
    return Identity.hasRole(['admin', 'dev']);
  }

  get comparedItems(): Array<any> {
    const results: any[] = [];
    if (this.compareDialog.rows[this.compareDialog.indexA]) {
      results.push(this.compareDialog.rows[this.compareDialog.indexA]);
    }
    if (this.compareDialog.rows[this.compareDialog.indexB]) {
      results.push(this.compareDialog.rows[this.compareDialog.indexB]);
    }
    return results;
  }

  getParsedHeaders(definitions: Array<IDefinition>): Array<string> {
    return this.dtPivotedHeaders.map(header => {
        const definition: IDefinition | undefined = definitions.find(definition => definition.name === header.text);
        header.slot = 'item.' + header.value;
        header.multiple = !!(definition && !definition.single);
        header.openMetadata = header.value === 'Name of Data Source'
        return header;
      });
  }

  getBeforeTabHeight(): number {
    const minHeight = window.innerHeight - 500;
    let height = minHeight;
    if (this.$refs.tabs) {
      const boundingClientRect = (this.$refs.tabs as any).$el.getBoundingClientRect();
      height = boundingClientRect.top;
    }

    height = window.innerHeight - height;
    if (height < minHeight) {
      height = minHeight;
    }

    return height - 32 - 29;
  }

  get showLoading(): boolean {
    return this.searching || this.loading || !this.parsed;
  }

  get canCompare(): boolean {
    return this.selectedRows.length >= 2;
  }

  get canExportTo(): boolean {
    return this.computedData.finalFilteredByQueryListRows.length > 0;
  }

  get canDatatableExportTo(): boolean {
    return this.selectedRows.length >= 1;
  }

  get canDetailsExportTo(): boolean {
    return true;
  }

  get dtHeaderFilters(): Array<any> {
    const items = [...this.dtHeaders].sort((a, b) => a.text > b.text ? 1 : -1);
    return items.filter(item => item.text !== 'Name of Data Source');
  }

  get dtHeaders(): Array<any> {
    return this.headers.map(header => {
      const values: any = {
        text: header.text,
        value: header.text,
        excerpt: this.truncateHeaders ? this.$options.filters?.excerpt(header.text, 32) : header.text,
        category: header.category,
        // subCategory: header.subCategory,
        class: 'text-no-wrap',
      };
      switch (header.text) {
        case 'Name of Data Source':
          values.fixed = true;
          values.width = 300;
          break;
        case 'Name of Linkable Data Source':
        case 'Details regarding Data Access(if available)':
        case 'Comments':
          values.width = 900;
          break;
      }
      return values;
    });
  }

  get isPivoted(): boolean {
    return this.compareDialog.pivot
      && this.compareDialog.view === 'tabular'
      && this.compareDialog.visible;
  }

  get dtPivotedHeaders(): Array<any> {
    if (this.isPivoted) {
      const key = 'Name of Data Source';
      return this.tabularRows.map(row => ({
        text: row[key],
        value: row[key],
        excerpt: this.truncateHeaders ? this.$options.filters?.excerpt(row[key], 32) : row[key],
        class: 'text-no-wrap',
      }));
    }
    return this.dtHeaders;
  }

  get dtHeadersCompare(): Array<any> {
    return this.dtPivotedHeaders.filter(item => item.text !== 'Name of Data Source');
  }

  get headersSlot(): Array<any> {
    return this.dtPivotedHeaders.map(header => ({
      ...header,
      slot: 'item.' + header.value,
    }))
  }

  get thSlot(): Array<any> {
    return this.dtHeaders.map(header => ({
      ...header,
      slot: 'header.' + header.value,
    }))
  }

  get tabularRows(): Array<any> {
    return this.compareDialog.rows;
  }

  get pivotedTabularRows(): Array<any> {
    if (this.isPivoted) {
      const rows: Array<any> = [];
      const keys = Object.keys(this.tabularRows[0]);
      const nodIndex = keys.findIndex((item: any) => item === 'Name of Data Source');
      keys.splice(nodIndex, 1);
      // keys.sort();
      keys.forEach((row) => {
        if (row.startsWith('___')) {
          return;
        }

        const item: any = {};

        this.dtHeadersCompare.forEach((header, headerIdx) => {
          item[header.value] = this.tabularRows[headerIdx][row];
        });

        if (this.isPivoted && this.compareDialog.selectedColumns.length > 0 && !this.compareDialog.selectedColumns.find(item => item === row)) {
          return;
        }

        if (this.isPivoted && this.compareDialog.hideSimilarities) {
          const keys = Object.keys(item);
          let hasDifferences = false;
          for (let i = 0; i < keys.length; i++) {
            const currentValue = item[keys[i]];
            if (item[keys[0]] !== currentValue) {
              hasDifferences = true;
            }
          }
          if (!hasDifferences) {
            return;
          }
        }

        item['Name of Data Source'] = row;
        rows.push(item);
      });
      return rows;
    }

    return this.tabularRows;
  }

  get legends(): Array<ILegend> {
    const filteredLegends: Array<ILegend> = [];
    this.filteredHeatmapRows.forEach(row => {
      if (this.selectedTracker) {
        this.selectedTracker.data.heatmap.data.columns.forEach((column: any) => {
          const attrs = this.getHeatmapColumnAttrs(column, row);
          const color = (attrs.style.backgroundColor || '').toUpperCase();
          const legend = filteredLegends.find(legend => legend.color === color);
          if (!legend && color) {
            const heatmapLegend = heatmapLegendsList.find(heatmapLegend => (this.$vuetify.theme.themes.light[heatmapLegend.color] || '').toString().toUpperCase() === color);
            const trackerLegend = this.selectedTracker?.data.heatmap.data.legends.find((item: any) => item.color === color);
            const text = trackerLegend
              ? trackerLegend.text
              : (heatmapLegend && heatmapLegend.text);
            const dark = tinycolor(color).isDark();
            if (text) {
              filteredLegends.push({
                color,
                text,
                dark,
              })
            }
          }
        })
      }
    })

    const sortedLegends: Array<ILegend> = [];
    this.selectedTracker?.data.heatmap.data.legends.forEach((legend: any) => {
      const filteredLegend = filteredLegends.find(filteredLegend => filteredLegend.color === legend.color);
      if (filteredLegend) {
        sortedLegends.push(filteredLegend);
      }
    });

    return sortedLegends;
  }

  onChangeTracker() {
    if (this.parsed) {
      this.reset();
      this.parsed = false;
      this.loading = true;
    }
  }

  onShowData(props: {
    rows: Array<any>,
    points: Array<any>,
  }) {
    Object.assign(this.compareDialog, {
      visible: true,
      panels: [],
      selectedColumns: [],
      rows: props.rows,
      indexA: 0,
      indexB: 1,
      view: 'tabular',
      hideSimilarities: false,
      showDiff: false,
      pivot: true,
      singleSelect: [props.rows[0]],
      selectDataSourceFirst: props.rows.length > 1,
      titlePrefix: null,
    })
  }

  onDuplicateDashboard() {
    const dashboard = this.dashboards[this.selectedDashboardIdx];
    if (dashboard) {
      const model = dashboard.clone() as VisualizationModel;
      model.data.id = null;
      model.data.userId = this.userId;
      this.onAddDashboard(model);
    }
  }

  onAddDashboard(model: VisualizationModel) {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id);
    this.dashboards.push(model);
    this.selectedDashboardIdx = this.dashboards.length - 1;
  }

  onDeleteDashboard(id: number) {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id && dashboard.data.id !== id);
    this.selectedDashboardIdx = 0;
  }

  onRemoveDashboard() {
    this.dashboards = this.dashboards.filter(dashboard => dashboard.data.id);
    this.selectedDashboardIdx = this.dashboards.length - 1;
  }

  onClickHeatmapCell(column: Column, columnIdx: number) {
    if (this.selectedTracker) {
      const trackerClone: TrackerModel = this.selectedTracker.clone();
      trackerClone.data.heatmap.data.columns = [column];
      Object.assign(this.queryInspectorDialog, {
        visible: true,
        disabled: true,
        index: columnIdx,
        tracker: trackerClone,
      })
    }
  }

  getHeatmapColumnAttrs(column: Column, row: {[key: string]: string}): {[key: string]: any} {
    const attrs = {
      style: {
        backgroundColor: column.color,
        outline: 'rgba(255, 255, 255, 0.6) solid 1px',
        cursor: 'pointer',
        width: 'calc(' + (50 / this.selectedTracker?.data.heatmap.data.columns.length) + '%)',
      }
    };
    for (let i = 0; i < column.items.length; i++) {
      const item = column.items[i];
      if (Query.analyze(item.queries, row, item.match)) {
        attrs.style.backgroundColor = item.color;
        return attrs;
      }
    }
    return attrs;
  }

  getComparedHeaders(rows: Array<{[key: string]: string}>): Array<any> {

    if (this.isPivoted) {
      const headers = [...this.dtPivotedHeaders];
      headers.unshift({
        text: 'Field',
        value: 'Name of Data Source',
        class: 'text-no-wrap',
        fixed: true,
        width: 300,
      });
      return headers;
    }

    const headers = this.compareDialog.hideSimilarities
      ? this.dtPivotedHeaders.filter(header => {

        if (this.compareDialog.selectedColumns.length > 0 && (this.compareDialog.selectedColumns.indexOf(header.text) === -1 || header.text === 'Name of Data Source')) {
          return false;
        }

        let rowsHaveDifference = false;
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];
          if (row[header.text] !== rows[i - 1][header.text]) {
            rowsHaveDifference = true;
          }
        }
        return rowsHaveDifference;
      })
      : this.dtPivotedHeaders.filter(header => {
        return !(this.compareDialog.selectedColumns.length > 0 && (this.compareDialog.selectedColumns.indexOf(header.text) === -1 || header.text === 'Name of Data Source'));
      });

    if (!headers.find(header => header.value === 'Name of Data Source')) {
      headers.unshift({
        text: 'Name of Data Source',
        value: 'Name of Data Source',
        class: 'text-no-wrap',
        fixed: true,
        width: 300,
      });
    }

    return headers;
  }

  get userOwnDashboard(): boolean {
    const dashboard = this.dashboards[this.selectedDashboardIdx];

    if (!dashboard) {
      return false;
    }
    return this.userId === null || this.userId === dashboard.data.userId;
  }

  get canDuplicate(): boolean {
    return true;
    // return !this.userOwnDashboard;
  }

  get canSearch(): boolean {
    return !this.searching && !this.loading &&
      JSON.stringify(this.filtersTmp) !== JSON.stringify(this.lastFilters) &&
      this.formIsValid;
  }

  get canReset(): boolean {
    return !this.searching && !this.loading &&
      JSON.stringify(this.filtersTmp) !== JSON.stringify([this.defaultFilterItem]);
  }

  get currentViewedItem(): {[key: string]: string} {
    return this.compareDialog.rows[this.compareDialog.indexA]
      ? this.compareDialog.rows[this.compareDialog.indexA]
      : {};
  }

  get finalFilters(): Array<IQueryItem> {
    return this.adjustFilters(this.filtersTmp);
  }

  cleanFilters(filters: Array<IQueryItem>): Array<IQueryItem> {
    function clean(filters: Array<IQueryItem>) {
      const items: Array<IQueryItem> = []
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        filter.group = clean(filter.group);
        if (filter.category !== 'study') {
          items.push(filter);
        }
      }
      return items;
    }

    return clean(structuredClone(filters))
  }

  adjustFilters(filters: Array<IQueryItem>): Array<IQueryItem> {
    function adjust(filters: Array<IQueryItem>) {
      const items: Array<IQueryItem> = []
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        filter.group = adjust(filter.group);
        if (filter.category === 'study' && filter.value && filter.value.length > 0) {
          filter.value = (filter.value || []).map((item: any) => item && item.color);
          items.push(filter);
        } else if (filter.category !== 'study' && filter.value && filter.value.length > 0) {
          items.push(filter);
        } else if (filter.group.length > 0) {
          items.push(filter);
        }
      }
      return items;
    }
    return adjust(structuredClone(filters));
  }

  get filteredRows(): Array<{[key: string]: string}> {
    return Query.filterItems(this.cleanFilters(this.filtersTmp), this.rows, false);
  }

  get finalFilteredRows(): Array<{[key: string]: string}> {
    const orderedRows = this.sorting ? this.filteredHeatmapRows.sort((a: any, b: any) => {
      return this.sorting === 'asc'
        ? (a['Name of Data Source'] > b['Name of Data Source']) ? 1 : -1
        : (a['Name of Data Source'] > b['Name of Data Source']) ? -1 : 1;
    }) : this.filteredHeatmapRows;
    const filters = this.adjustFilters(this.filtersTmp);
    return Query.filterItems(filters, orderedRows, false);
  }

  get finalFilteredByQueryListRows(): Array<{[key: string]: string}> {
    const query = (this.queryList || '').toLowerCase();
    const rows = this.filteredHeatmapRows.filter(row => (row['Name of Data Source'] || '').toString().toLowerCase().indexOf(query) !== -1);
    const orderedRows = this.sorting ? rows.sort((a: any, b: any) => {
      return this.sorting === 'asc'
        ? (a['Name of Data Source'] > b['Name of Data Source']) ? 1 : -1
        : (a['Name of Data Source'] > b['Name of Data Source']) ? -1 : 1;
    }) : rows;
    const filters = this.adjustFilters(this.filtersTmp);
    return Query.filterItems(filters, orderedRows, false);
  }

  get filteredHeatmapRows(): Array<{[key: string]: string}> {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const rows: Array<{[key: string]: string}> = [];
    const legendIndexes: {[key: string]: string} = this.selectedTracker?.data.heatmap.data.legends.reduce((obj: any, legend: any, index: number) => {
      obj[legend.color.substring(0, 7)] = letters.substring(index, index + 1);
      return obj;
    }, {});

    this.filteredRows.forEach(row => {
      const item: {[key: string]: string} = {};
      Object.keys(row).forEach(key => {
        item[key] = row[key];
      })
      this.heatmapHeaders.slice(1).forEach((header: any) => {
        item['___' + header.text] = legendIndexes[header.meta.color.substring(0, 7)] + '_' + header.meta.color;
        for (let i = 0; i < header.meta.items.length; i++) {
          const _item = header.meta.items[i];
          if (Query.analyze(_item.queries, row, _item.match)) {
            item['___' + header.text] = legendIndexes[_item.color.substring(0, 7)] + '_' + _item.color;
            break;
          }
        }
      })
      rows.push(item)
    });

    return rows;
  }

  get heatmapHeaders(): Array<{[key: string]: string}> {
    return [
      { value: 'Name of Data Source', text: 'Name of Data Source', align: 'left', class: 'v-align-top' },
      ...this.selectedTracker?.data.heatmap.data.columns.map((item: any) => ({
        value: '___' + item.name,
        text: item.name,
        meta: item,
        class: 'v-align-top',
        align: 'center'
      }))
    ]
  }

  get categories(): Array<ICategory> {
    const results: Array<ICategory> = [];
    this.dtPivotedHeaders.forEach((header, headerIdx: number) => {
      let category = results.find(item => item.text === header.category)
      if (!category) {
        category = {
          text: header.category,
          headers: [],
        };
        results.push(category);
      }
      category.headers.push({
        index: headerIdx,
        data: header,
      })
    })
    return results;
  }

  get markers(): Array<IMarker> {
    const markers: Array<IMarker> = [];

    if (this.countryList.length === 0) {
      return markers;
    }

    this.finalFilteredRows.forEach(row => {
      const rowCountries = this.splitValues((row['Country(ies)'] || '').toString());
      rowCountries.forEach(rowCountry => {
        rowCountry = rowCountry.trim().toLowerCase();
        if (this.csvCountryMapping[rowCountry]) {
          rowCountry = this.csvCountryMapping[rowCountry];
        }
        rowCountry = rowCountry.toLowerCase().trim();

        const found = this.countryList.find(country => {
          const countryLabel = country.data.label.toLowerCase().trim();
          return countryLabel === rowCountry;
        });
        if (found) {
          const foundInMarkers = markers.find(marker => marker.model.data.label === found.data.label);
          if (!foundInMarkers) {
            markers.push({
              model: found,
              title: 1,
              meta: {
                rows: [row],
              },
              position: {
                lat: found.data.latitude,
                lng: found.data.longitude,
              }
            })
          } else if (foundInMarkers.meta) {
            foundInMarkers.meta.rows.push(row);
            foundInMarkers.title++;
          }
        } else {
          console.error('COUNTRY NOT FOUND', rowCountry);
        }
      });
    });
    return markers;
  }

  onSubmitForm() {

    this.selectedRows = [];

    this.lastFilters = structuredClone(this.filtersTmp);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      this.beforeTabHeight = this.getBeforeTabHeight();
    })

    // Must be done in order and one by one to insure
    // computing and dependencies
    this.computedData.finalFilters = this.finalFilters;
    this.computedData.filteredRows = this.filteredRows;
    this.computedData.filteredHeatmapRows = this.filteredHeatmapRows;
    this.computedData.finalFilteredRows = this.finalFilteredRows;
    this.computedData.finalFilteredByQueryListRows = this.finalFilteredByQueryListRows;
    this.computedData.dtHeaders = this.dtHeaders;
    this.computedData.dtPivotedHeaders = this.dtPivotedHeaders;
    this.computedData.dtHeadersCompare = this.dtHeadersCompare;
    this.computedData.legends = this.legends;
    this.computedData.heatmapHeaders = this.heatmapHeaders;

    this.updateEvents();
  }

  resetFilters() {
    this.presetFilter = null;
    this.filtersTmp.splice(0, this.filtersTmp.length, structuredClone(this.defaultFilterItem));
    this.filterList = structuredClone(this.originalFilterList);
    this.lastFilters = structuredClone(this.filtersTmp);

    if (this.presetManager) {
      this.presetManager.reset();
    }
  }

  onResetClick() {
    this.resetFilters();

    if (this.presetManager) {
      this.presetManager.reset();
    }

    this.onSubmitForm();
  }

  getGroupedData(row: any) {
    const currentKeys = Object.keys(row);
    const results: Array<any> = [];
    this.dtHeaders.forEach((header, headerIdx: number) => {
      let newCategory = results.find(item => item.label === header.category);
      if (!newCategory) {
        newCategory = {
          label: header.category,
          keys: [],
          children: [],
          unclassified: [],
        };

        const subCategories = [...new Set(this.definitions.filter(definition => definition.subCategory && definition.category === header.category))];
        subCategories.forEach(subCategory => {
          let newSubCategory = newCategory.children.find((item: any) => subCategory.subCategory && item.label === subCategory.subCategory);
          if (!newSubCategory) {
            newSubCategory = {
              key: subCategory.name,
              label: subCategory.subCategory,
              children: [],
            }
            newCategory.children.push(newSubCategory);
          }
          const keys = currentKeys.filter(key => key === subCategory.name);
          keys.forEach(key => {
            newSubCategory.children.push({
              label: key,
              value: row[key],
            })
          })
        });
        results.push(newCategory);
      }
      newCategory.keys.push(currentKeys[headerIdx]);
    });

    results.forEach(category => {
      const remainingKeys: Array<string> = category.keys.filter((key: string) => {
        return !category.children.find((subCategory: any) =>
            subCategory.key === key || subCategory.children.find((subSubCategory: any) => {
              return subSubCategory.label === key;
            })
        )
      });
      if (remainingKeys.length > 0) {
        remainingKeys.forEach(key => {
          category.unclassified.push({
            label: key,
            value: row[key],
          })
        })
      }
    });

    return results;
  }

  getExportToData(items: Array<any>, title: string): IExportToData {
    const newItems: Array<IExportToItem> = [];
    items.forEach(item => {
      newItems.push({
        label: item['Name of Data Source'],
        categories: this.getGroupedData(item),
      });
    })

    return {
      title,
      items: newItems,
    }
  }

  onListItemClick(
    row: Array<{[key: string]: string}>,
  ) {
    Object.assign(this.compareDialog, {
      visible: true,
      panels: [],
      selectedColumns: [],
      rows: [row],
      indexA: 0,
      indexB: 1,
      view: 'tabular',
      hideSimilarities: false,
      showDiff: false,
      pivot: true,
      singleSelect: [row],
      selectDataSourceFirst: false,
      titlePrefix: null,
    })
  }

  onSelectDataSourceClick(index: number, row: any) {
    Object.assign(this.compareDialog, {
      selectDataSourceFirst: false,
      rows: [row],
      index,
      titlePrefix: null,
    })
  }

  onClickMarker(marker: IMarker) {
    Object.assign(this.compareDialog, {
      visible: true,
      panels: [],
      selectedColumns: [],
      rows: marker.meta ? marker.meta.rows : [],
      indexA: 0,
      indexB: 1,
      view: 'tabular',
      hideSimilarities: false,
      showDiff: false,
      pivot: true,
      singleSelect: marker.meta ? [marker.meta.rows[0]] : [],
      selectDataSourceFirst: true,
      titlePrefix: marker.model.data.label,
    })
  }

  onFlipIndexes() {
    const indexA = this.compareDialog.indexA;
    const indexB = this.compareDialog.indexB;
    this.compareDialog.indexA = indexB;
    this.compareDialog.indexB = indexA;
  }

  onPivotTable() {
    this.compareDialog.pivot = !this.compareDialog.pivot;
    this.compareDialog.selectedColumns = [];
  }

  onLoadTrackers() {
    this.keepViewState = JSON.parse(localStorage.getItem('tracker_keep_view_state') || 'false') || false;
    if (this.keepViewState) {
      this.loadViewState();
    } else if (this.trackerAutocomplete && this.trackerList.length > 0) {
      this.selectedTracker = this.trackerList[0];
      this.trackerAutocomplete.onChange(this.trackerList[0]);
    }
  }

  onParseTrackers({ headers, rows, filters, definitions, abbreviations }: any) {
    this.headers = headers;
    this.rows = rows;
    this.definitions = definitions;
    this.abbreviations = abbreviations;
    this.parsedHeaders = this.getParsedHeaders(definitions);
    this.filterList = filters;

    this.originalFilterList = structuredClone(filters);
    this.loadPresets();

    if (this.selectedTracker) {
      this.loadDashboards(this.selectedTracker);
    }

    this.parsed = true;
    this.loading = false;

    this.onSubmitForm();
  }

  onScrollDatatable(e: any, timeout = 50) {
    if (this.datatableMaxHeader < this.computedData.dtHeaders.length) {
      clearTimeout(scrollDatatableTimeout);
      scrollDatatableTimeout = setTimeout(() => {
        const table = e.target.parentElement;
        const scrollLeft = e.target.scrollLeft;
        let left = 0;
        table.querySelectorAll('thead tr th').forEach((th: any, thIdx: number) => {
          left += th.offsetWidth;
          if (left <= (scrollLeft + table.offsetWidth) && this.datatableMaxHeader < thIdx) {
            this.datatableMaxHeader = thIdx;
          }
        })
      }, timeout);
    }
  }

  updateEvents() {
    if (this.tabName === 'datatable') {
      this.datatableMaxHeader = 0;
      setTimeout(() => {
        if (this.$refs.datatable) {
          // @ts-ignore
          this.$refs.datatable.$el.children[0].addEventListener('scroll', this.onScrollDatatable)
          this.onScrollDatatable({
            // @ts-ignore
            target: this.$refs.datatable.$el.children[0]
          }, 0);
        }
      })
    }
  }

  loadPresets(): Promise<any> {
    return TrackerFilterStateService.getInstance().getAll({
      filters: [{
        field: 'trackerId',
        operator: 'equals',
        value: this.selectedTracker?.data.id,
      }, [{
        field: 'userId',
        operator: 'is null',
      }, {
        field: 'userId',
        operator: 'equals',
        value: Identity.getIdentity()?.user.id,
      }]]
    }).then(response => response.data.view.list)
      .catch(reason => this.$root.$zemit.handleError(reason));
  }

  savePresets(item: any) {
    return TrackerFilterStateService.getInstance().save(item)
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  removePreset(item: any) {
    return TrackerFilterStateService.getInstance().delete({
      id: item.data.id,
    })
      .then(response => response.data.view.single)
      .catch(reason => this.$root.$zemit.handleError(reason))
  }

  saveViewState(): void {
    if (this.keepViewState && this.parsed) {
      localStorage.setItem('tracker_view_state', JSON.stringify({
        selectedTracker: this.selectedTracker?.data.id,
        tab: this.tab,
        selectedDashboardIdx: this.selectedDashboardIdx,
        filtersTmp: this.filtersTmp,
        selectedRows: this.selectedRows,
      }));
    }
  }

  loadViewState(): void {
    const state = JSON.parse(localStorage.getItem('tracker_view_state') || '{}');

    this.filtersTmp.splice(0, this.filtersTmp.length, ...(structuredClone(state.filtersTmp) || []));

    if (state.selectedTracker) {
      this.selectedTracker = this.trackerList.find(tracker => tracker.data.id === state.selectedTracker) || null;

      if (this.trackerAutocomplete) {
        this.trackerAutocomplete.onChange(this.selectedTracker as TrackerModel);
      }
    }
    this.tab = state.tab ? state.tab : this.tab;
    // this.selectedDashboardIdx = state.selectedDashboardIdx ? state.selectedDashboardIdx : this.selectedDashboardIdx;
    this.selectedRows = state.selectedRows || [];
  }

  loadCountries(): Promise<Array<CountryModel>> {
    this.loading = true;
    return CountryService.getInstance().getAll().then((response: any) => {
      this.countryList = response.data.view.list;
      return this.countryList;
    }).finally(() => this.loading = false);
  }

  loadDashboards(tracker: TrackerModel): Promise<Array<VisualizationModel>> {
    this.loadingDashboards = true;
    return VisualizationService.getInstance().getAll({
      filters: [{
        field: 'trackerId',
        operator: 'equals',
        value: tracker.data.id,
      }, {
        field: 'deleted',
        operator: 'equals',
        value: '0'
      }]
    })
      .then(response => {
        this.dashboards = response.data.view.list;
        if (this.dashboards.length > 0) {
          this.dashboard = response.data.view.list[0];
        } else {
          this.dashboard = new VisualizationModel();
        }
        return response.data.view.list;
      })
      .finally(() => this.loadingDashboards = false);
  }

  reset(): void {
    this.filterList = []
    this.headers = []
    this.definitions = []
    this.rows = []
    this.dashboard = new VisualizationModel({
      title: 'Tracker Visualization',
      graphs: [],
    })

    this.resetFilters();
  }

  created() {
    this.reset();
    this.loadCountries();

    // this.truncateHeaders = JSON.parse(localStorage.getItem('tracker_truncate_headers') || 'true');
  }
}
</script>

<style lang="scss" scoped>
#metadata_content {
  position: absolute;
  opacity: 0.01;
  max-height: 100px;
  overflow: auto;
}
.dt-compare ::v-deep .v-data-table__wrapper {
  max-height: calc(100vh - 340px);
}
</style>
