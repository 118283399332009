<template>
  <v-app v-test-id="'app'">
    <v-navigation-drawer
      v-if="showSidebar"
      v-test-id="'app-navigation'"
      v-model="drawer"
      :mini-variant="_mini"
      permanent
      dark
      app
    >
      <template #prepend>
        <v-list-item class="px-2" v-if="canMini">
          <v-btn
            v-test-id="'app-mini-toggle-button2'"
            icon
            @click.stop="() => _mini = !_mini"
          >
            <v-icon v-if="!_mini">mdi-chevron-left</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item>
        <v-tooltip :disabled="!_mini" right>
          <template #activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" class="px-2">
              <v-list-item-avatar>
                <v-btn
                  icon
                  :to="{name: 'UserForm', params: {id: currentUserId}}"
                >
                  <v-icon>mdi-account-circle-outline</v-icon>
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-title>
                {{ userObj.fullName }}
              </v-list-item-title>

              <v-btn
                v-if="!_mini"
                v-test-id="'app-mini-toggle-button'"
                icon
                @click.stop="() => _mini = !_mini"
              >
                <v-icon v-if="!_mini">mdi-chevron-left</v-icon>
                <v-icon v-else>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item>
          </template>
          <span>{{ userObj.fullName }}</span>
        </v-tooltip>

        <v-divider></v-divider>
      </template>

      <template #append>
        <v-slide-y-reverse-transition>
          <v-sheet v-if="!_mini" class="caption text-right text-no-wrap" dark>
            <div class="px-4 py-1" style="opacity: 0.5" v-text="$t('footer.version', {
              version,
            })"></div>
          </v-sheet>
        </v-slide-y-reverse-transition>
      </template>

      <v-list dense>
        <div
          v-for="(group, groupIdx) in menuList"
          :key="groupIdx"
        >
          <v-subheader
            v-if="group.children && group.label"
            v-text="group.label"
            class="d-block text-truncate pt-3"
          ></v-subheader>
          <v-tooltip
            v-for="item in group.children"
            :key="item.title"
            :disabled="!_mini"
            right
          >
            <template #activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                :to="item.to"
                link
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span v-text="item.title"></span>
          </v-tooltip>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar id="toolbar" app style="z-index: 100">
      <div class="d-flex align-center ml-1" style="gap: 1rem">
        <v-btn
          :to="{name: 'HomeView'}"
          active-class="v-btn-hide-active"
          exact
          text
          x-large
        >
          <img
            :src="require('@/assets/images/logo.svg')"
            :style="
              ($vuetify.theme.dark ? 'filter: invert(1);' : '')
              + 'height: 3rem;'
            "
            alt="Logo"
          />
        </v-btn>

        <v-chip
          v-if="!showSidebar"
          label
          dark
        >
          <v-icon small left>mdi-account</v-icon>
          {{ userObj.fullName }}
        </v-chip>

        <v-chip
          v-if="!['prod'].includes(env.key)"
          :color="env.color"
          label
        >
          <v-avatar class="flashing" left>
            <v-icon>{{ env.icon }}</v-icon>
          </v-avatar>
          {{ env.title }}
        </v-chip>
      </div>

      <v-spacer />

      <div class="d-flex align-center justify-end" style="gap: 0.5rem; flex: 1">

        <!-- PORTAL -->
        <portal-target
          class="d-flex align-center mr-3"
          name="toolbar_right"
          style="gap: 1rem"
          multiple
        />

        <MenuTooltip
          :btn-attrs="{
            text: true,
          }"
          icon="mdi-help-circle-outline"
          btn-text="Help"
          chevron-down
        >
          <v-list>
            <v-list-item @click="() => showHelp = true">
              <v-list-item-icon>
                <v-icon>mdi-help-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>General Help</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="() => showSurvey = true">
              <v-list-item-icon>
                <v-icon>mdi-check-decagram</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Satisfaction Feedback</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'TutorialView' }">
              <v-list-item-icon>
                <v-icon>mdi-video-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Video Library</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </MenuTooltip>

        <!-- DARK MODE -->
        <theme-toggle/>

        <!-- NOTIFICATIONS -->
        <notification-menu/>

        <!-- HELP -->
        <TutorialModal
          :visible.sync="showHelp"
        />

        <!-- SURVEY -->
        <SatisfactionSurveyModal
          :visible.sync="showSurvey"
        />

        <!-- LOGOUT -->
        <LogoutModal
          :visible.sync="showLogout"
          dark
        />
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-test-id="'app-logout-button'"
              v-bind="attrs"
              v-on="on"
              icon
              text
              @click="() => showLogout = true"
            >
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span v-test-id="'app-logout-tooltip'">
            Logout
          </span>
        </v-tooltip>
      </div>
    </v-app-bar>

    <v-main>
      <v-container fluid class="background fill-height align-start">
        <router-view :key="$route.fullPath" class="w-100"></router-view>
      </v-container>
    </v-main>

    <v-footer padless fixed app inset style="border-top: rgba(0,0,0,.1) solid 1px">
      <div class="caption pa-1 text-center w-100">
        {{ 'Copyright © 2023-' + new Date().getFullYear() }} YolaRx Consultant Inc. — <strong>All rights reserved.</strong>
      </div>
    </v-footer>

    <global-confetti/>
    <global-overlay/>
    <global-snack top app/>
    <global-modal scrollable/>
    <global-prompt/>
    <should-take-action/>
  </v-app>
</template>

<script lang="ts">
import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import LocaleSwitcher from '@/modules/common/components/LocaleSwitcher.vue'
import Identity from '@/modules/sdk/core/identity'
import GlobalSnack from '@/modules/common/components/GlobalSnack.vue'
import NotificationMenu from '@/modules/common/components/NotificationMenu.vue'
import GlobalOverlay from '@/modules/common/components/GlobalOverlay.vue'
import GlobalPrompt from '@/modules/common/components/GlobalPrompt.vue'
import GlobalModal from '@/modules/common/components/GlobalModal.vue'
import ShouldTakeAction from '@/modules/common/components/ShouldTakeAction.vue'
import Logger from '@/modules/sdk/core/logger'
import GlobalConfetti from '@/modules/common/components/GlobalConfetti.vue'
import VueI18n from 'vue-i18n'
import ThemeToggle from '@/modules/common/components/ThemeToggle.vue'
import InlineTutorial from '@/modules/common/components/InlineTutorial.vue'
import MenuTooltip from '@/modules/common/components/MenuTooltip.vue'
import SatisfactionSurveyModal from '@/modules/common/components/SatisfactionSurveyModal.vue'
import LogoutModal from '@/modules/common/components/LogoutModal.vue'
import TutorialModal from '@/modules/common/components/TutorialModal.vue';
import UserModel from '@/modules/sdk/models/user.model';

const d = new Logger('App')

interface ISidebarItem {
  title: string,
  to: {
    name: string | VueI18n.LocaleMessages,
  },
  icon: string,
  show?: boolean
}

interface ISidebarGroup {
  label?: string | VueI18n.LocaleMessages,
  children: Array<ISidebarItem>,
  show?: boolean
}

@Component({
  components: {
    TutorialModal,
    ThemeToggle,
    GlobalConfetti,
    GlobalOverlay,
    LocaleSwitcher,
    GlobalSnack,
    GlobalModal,
    GlobalPrompt,
    ShouldTakeAction,
    NotificationMenu,
    InlineTutorial,
    MenuTooltip,
    SatisfactionSurveyModal,
    LogoutModal,
  }
})
export default class App extends Vue {
  mini = false
  isFullscreen = false
  drawer = true
  showHelp = false
  showSurvey = false
  showLogout = false
  showSidebar = !Identity.hasRole(['client'])
  user = Identity.identity?.user
  version = process.env.VUE_APP_PACKAGE_VERSION

  get userObj() {
    return new UserModel(this.user);
  }

  get env(): { key: string, title: string, color: string, icon: string } {
    let env = location.hostname.split('.')[0];
    if (env === 'localhost') {
      env = 'dev';
    } else if (env === 'slr' || env === 'glosarx') {
      env = 'prod';
    }
    const names: any = { dev: 'Development', test: 'Testing', stage: 'Staging', prod: 'slr' };
    const colors: any = { dev: 'error', test: 'error', stage: 'warning', prod: 'primary' };
    const icons: any = { dev: 'mdi-alert', test: 'mdi-alert', stage: 'mdi-alert', prod: 'mdi-cloud-check' };
    return {
      key: env,
      title: names[env],
      color: colors[env],
      icon: icons[env],
    }
  }

  get menuList() {

    const dev = Identity.hasRole(['dev'])
    const admin = Identity.hasRole(['admin'])
    const leader = Identity.hasRole(['leader'])
    const arbitrator = Identity.hasRole(['arbitrator'])
    const researcher = Identity.hasRole(['researcher'])
    const tracker = Identity.hasRole(['tracker'])
    const viewer = Identity.hasRole(['viewer'])
    const client = Identity.hasRole(['client'])

    const items: Array<ISidebarGroup> = [
      {
        children: [
          {
            title: 'Dashboard',
            to: { name: 'TrackerView' },
            icon: 'mdi-map-search',
            show: dev || admin || tracker || client
          },
        ]
      },

      // Admin
      {
        show: admin || dev || leader || tracker,
        label: this.$i18n.t('sidebar.admin'),
        children: [
          { title: 'Trackers', to: { name: 'TrackerList' }, icon: 'mdi-file-chart-outline', show: dev || admin || tracker},
        ]
      },

      // Settings
      {
        show: dev || admin || leader,
        label: this.$i18n.t('sidebar.settings'),
        children: [
          { title: 'Countries', to: { name: 'CountryList' }, icon: 'mdi-earth', show: dev || admin },
        ]
      },

      // Managements
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.management'),
        children: [
          { title: 'Users', to: { name: 'UserList' }, icon: 'mdi-account-outline', show: dev || admin },
          { title: 'Roles', to: { name: 'RoleList' }, icon: 'mdi-briefcase-account-outline', show: dev },
          { title: 'Notifications', to: { name: 'NotificationList' }, icon: 'mdi-bell-outline', show: dev || admin },
          { title: 'Templates', to: { name: 'TemplateList' }, icon: 'mdi-clipboard-text', show: dev || admin },
          { title: 'Video Library', to: { name: 'VideoLibraryList' }, icon: 'mdi-video-outline', show: dev || admin },
        ]
      },

      // Managements
      {
        show: dev || admin,
        label: this.$i18n.t('sidebar.inscriptions'),
        children: [
          { title: 'Contacts', to: { name: 'ContactList' }, icon: 'mdi-comment-multiple-outline', show: dev || admin },
          { title: 'Satisfactions', to: { name: 'SatisfactionList' }, icon: 'mdi-certificate', show: dev || admin },
        ]
      },

      // Dev
      {
        show: dev,
        label: this.$i18n.t('sidebar.dev'),
        children: [
          { title: 'Sandbox', to: { name: 'DevView' }, icon: 'mdi-developer-board' },
        ]
      },
    ]

    return items.filter(item => ((item.children || []).length > 0 || item.label === undefined) && item.show !== false).map(item => {
      item.children = item.children.filter(child => child.show !== false)
      return item
    })
  }

  get canMini() {
    return this.$vuetify.breakpoint.mdAndUp && this.mini;
  }

  get _mini() {
    return this.isFullscreen
    || this.$vuetify.breakpoint.smAndDown ? true : this.mini
  }


  set _mini(value) {
    this.mini = value
  }

  get currentUserId() {
    return Identity.getIdentity()?.user.id
  }

  created() {
    this.$root.$on('toggleFullscreen', (isFullscreen: boolean) => {
      this.isFullscreen = isFullscreen
    })
  }
}
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

</style>
