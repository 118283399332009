<template>
  <GmapMap
    :center="{lat: 30, lng: 0}"
    :zoom="2"
    :options="options"
    map-type-id="terrain"
  >
    <GmapMarker
      v-for="(marker, markerIdx) in markers"
      :key="markerIdx"
      :title="showTitle ? marker._title : null"
      :label="showLabel ? (marker.title ? {
        text: marker.title.toString(),
        color: 'white',
      } : null) : null"
      :position="marker.position_chache ? marker.position_chache = marker.position : marker.position"
      :draggable="draggable"
      className="test"
      @click="$emit('click', marker)"
      @mouseover="() => onMouseOver(marker)"
      @mouseout="() => onMouseOut(marker)"
      @drag="location => onPositionChange(location, marker)"
    />
  </GmapMap>
</template>

<script lang="ts">
import {Vue, Component, VModel, Prop} from 'vue-property-decorator';
import CountryModel from '@/modules/sdk/models/country.model';

let positionChangeTimeout: any;

export interface IMarker {
  model: CountryModel,
  label?: string,
  title?: any,
  meta?: {[key: string]: any},
  position: {
    lat: number,
    lng: number,
  }
}

@Component
export default class GMapsMarkerManager extends Vue {
  @VModel() markers!: Array<any>;
  @Prop({ type: Boolean, default: false }) showLabel!: boolean;
  @Prop({ type: Boolean, default: false }) showTitle!: boolean;
  @Prop({ type: Boolean, default: false }) streetViewControl!: boolean;
  @Prop({ type: Boolean, default: false }) disableDefaultUi!: boolean;
  @Prop({ type: Boolean, default: true }) scrollwheel!: boolean;
  @Prop({ type: Boolean, default: true }) mapTypeControl!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) draggable?: boolean;

  styles = [
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#fffffa'
        }
      ]
    },
    {
      featureType: 'water',
      stylers: [
        {
          color: '#c0d7ff'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          lightness: 40
        }
      ]
    }
  ]

  get options() {
    return {
      streetViewControl: this.streetViewControl,
      disableDefaultUI: this.disableDefaultUi,
      scrollwheel: this.scrollwheel,
      mapTypeControl: this.mapTypeControl,
      styles: this.styles,
    }
  }

  onMouseOver(marker: any) {
    if (!marker.hovering) {
      marker.hovering = true;
      marker.label = {
        text: marker.model.data.label,
        color: '#ffffff',
      };
      if (this.showTitle) {
        marker._title = marker.model.data.label;
      }
    }
    this.$forceUpdate();
  }

  onMouseOut(marker: any) {
    if (marker.hovering) {
      marker.label = '';
      if (this.showTitle) {
        marker._title = '';
      }
      marker.hovering = false;
    }
    this.$forceUpdate();
  }

  onPositionChange(location: any, marker: any) {
    clearTimeout(positionChangeTimeout);
    positionChangeTimeout = setTimeout(() => {
      Object.assign(marker.position, {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      });
    }, 300);
  }
}
</script>
