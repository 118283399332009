export default class Utils {

  static removeLineBreaks(str: string) {
    return str.replace(/(\r\n|\n|\r)/gm, '');
  }

  static toCamelCase(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '')
      .replace(/\s+/gm, '');
  }

  static splitIgnoringCommasInQuotes(input = ''): string[] {
    // Regular expression to split the string by commas not enclosed by quotes
    const regex = /(?!\B"[^"]*),(?![^"]*"\B)/g;
    // Split the string by the regex, then map through each item to trim spaces and remove surrounding quotes
    return (input || '').split(regex).map(item => item.trim().replace(/^"|"$/g, ''));
  }

  /**
   *
   * @param html
   */
  static stripHtml(html: string, limit: number | null = null): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const text = doc.body.textContent || '';

    return limit === null
      ? text
      : text.length > limit
        ? text.substring(0, limit)
        : text;
  }
}
