import Vue, { DirectiveBinding, VNode } from 'vue';
import FileModel from '@/modules/sdk/models/file.model';
import FileService from '@/modules/sdk/services/file.service';
import Identity from '@/modules/sdk/core/identity';

export interface IAbstractSecureUrl {
  file: FileModel,
  onLoad?: () => void,
  onLoaded?: (url?: string) => void,
}

export interface ISecureUrl {
  file: FileModel,
  mapToAttribute: string,
  stream: boolean,
  onLoad: () => void,
  onLoaded: (url?: string) => void,
}

const stream = (data: ISecureUrl, vNode: VNode) => {
  const jwt = Identity.getIdentity()?.jwt;
  const mediaSource = new MediaSource();
  const headers = {
    'X-Authorization': `Bearer ${jwt}`
  };

  let sourceBuffer: SourceBuffer;
  mediaSource.addEventListener('sourceopen', async () => {
    if (!sourceBuffer) {
      sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.42E01E, mp4a.40.2"');

      const videoUrl = '/file/download/' + data.file.data.category + '/' + data.file.data.id + '/' + data.file.data.path;
      const response = await fetch(videoUrl, { headers });
      const reader = response.body?.getReader();

      const pump = async () => {
        // @ts-ignore
        const { done, value } = await reader.read();
        if (done) {
          if (mediaSource.readyState === 'open') {
            mediaSource.endOfStream();
          }
          return;
        }

        if (mediaSource.readyState === 'open' && !sourceBuffer.updating) {
          // @ts-ignore
          sourceBuffer.appendBuffer(value);
          await new Promise(resolve => {
            sourceBuffer.addEventListener('updateend', response => {
              setTimeout(() => {
                data.onLoaded(videoUrl);
              }, 1000);
              resolve(response);
            }, { once: true });
          });
        }

        pump();
      };

      pump();
    }
  });

  const url = URL.createObjectURL(mediaSource);
  applyUrl(url, data.mapToAttribute, vNode);
  data.onLoad();
}

const applyUrl = (url: string, attribute: string, vNode: VNode) => {
  if (vNode.componentInstance) {
    // We disable and re-enable Vue warnings since we dynamically update
    // the component's attribute which shouldn't be done outside of its
    // context.
    Vue.config.silent = true;
    vNode.componentInstance.$props[attribute] = url;
    Vue.config.silent = false
  } else {
    (vNode.elm as any)[attribute] = url;
  }

  emit('apply', vNode);
}

const emit = (name: string, vNode: VNode) => {
  // @ts-ignore
  vNode.context.$emit(name);
}

const download = (data: ISecureUrl, vNode: VNode) => {
  data.onLoad();
  const jwt = Identity.getIdentity()?.jwt;
  const url = '/file/stream/' + data.file.data.category + '/' + data.file.data.id + '/' + data.file.data.path + '?jwt=' + jwt;
  applyUrl(url, data.mapToAttribute, vNode);
  data.onLoaded(url);
}

const init = (binding: DirectiveBinding<FileModel | IAbstractSecureUrl>, vNode: VNode) => {
  const data: ISecureUrl = {
    file: binding.value instanceof FileModel ? binding.value : binding.value.file,
    mapToAttribute: binding.arg || 'src',
    stream: binding.modifiers.stream,
    onLoad: () => {},
    onLoaded: () => {},
  };
  if (!(binding.value instanceof FileModel)) {
    Object.assign(data, binding.value);
  }
  if (data.stream) {
    stream(data, vNode);
  } else {
    download(data, vNode);
  }
}

Vue.directive('secure-url', {
  bind: (el: HTMLElement, binding: DirectiveBinding<FileModel | IAbstractSecureUrl>, vNode: VNode) => {
    init(binding, vNode);
  },
  update: (el: HTMLElement, binding: DirectiveBinding<FileModel | IAbstractSecureUrl>, vNode: VNode) => {
    // init(binding, vNode);
  },
});
