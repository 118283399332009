<template>
  <ModalDialog
    v-model="visible"
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    :color="color"
    :dark="dark"
    max-width="450"
  >
    <template #body>
      <div
        v-test-id="'dialog-body'"
        v-html="body"
      ></div>
    </template>
    <template #buttons>
      <v-btn
        v-test-id="'dialog-confirm-button'"
        :color="dark ? null : 'primary'"
        @click="onActionClick"
      >
        <span v-text="actionText"></span>
      </v-btn>
      <v-btn
        v-test-id="'dialog-cancel-button'"
        :outlined="!dark"
        :text="dark"
        @click="onCancelClick"
      >
        <span v-text="$t('btn.cancel')"></span>
      </v-btn>
    </template>
  </ModalDialog>
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ModalDialog from '@/modules/common/components/ModalDialog.vue';

@Component({
  components: {
    ModalDialog,
  }
})
export default class ShouldTakeAction extends Vue {

  visible = false
  title: string | TranslateResult | null = null
  body: string | TranslateResult | null = null
  actionText: string | TranslateResult | null = null
  color: string | null = null
  dark = false
  resolve: ((response: any) => void) | null = null
  reject: ((response: any) => void) | null = null

  @Watch('visible')
  onVisibleChange(visible: false) {
    if (!visible && this.resolve) {
      this.resolve(false);
    }
  }

  ask(
    title?: string | TranslateResult | null,
    body?: string | TranslateResult | null,
    actionText?: string | TranslateResult | null,
    color: string | null = null,
    dark = false,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.title = title || this.$i18n.t('shouldTakeAction.default.title');
      this.body = body || this.$i18n.t('shouldTakeAction.default.body');
      this.actionText = actionText || this.$i18n.t('btn.confirm');
      this.color = color;
      this.dark = dark;
      this.resolve = resolve;
      this.reject = reject;
      this.show();
    })
  }

  askDelete(): Promise<boolean> {
    return this.ask(
      this.$i18n.t('shouldTakeAction.delete.title'),
      this.$i18n.t('shouldTakeAction.delete.body'),
      this.$i18n.t('btn.delete'),
      'error',
      true,
    );
  }

  askRestore(): Promise<boolean> {
    return this.ask(
      this.$i18n.t('shouldTakeAction.restore.title'),
      this.$i18n.t('shouldTakeAction.restore.body'),
      this.$i18n.t('btn.restore'),
      'success',
      true,
    );
  }

  onActionClick(): void {
    if (this.resolve) {
      this.resolve(true);
      this.resolve = null;
      this.hide();
    }
  }

  onCancelClick(): void {
    if (this.resolve) {
      this.resolve(false);
      this.hide();
    }
  }

  show(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }

  created() {
    this.$root.$shouldTakeAction = this;
  }
}
</script>
