<template>
  <v-container fluid class="pa-4">
    <v-card>
      <v-card-title class="align-center justify-space-between">
        <span>
          <v-icon left>mdi-map-marker-multiple</v-icon>
          <span>Positions</span>
        </span>
        <div class="d-flex align-center" style="gap: 1rem">
          <v-btn
            :disabled="!markersAreDifferent || savingCountries"
            text
            @click="onResetPositionClick"
          >
            <span>Reset</span>
          </v-btn>
          <v-btn
            :loading="savingCountries"
            :disabled="!markersAreDifferent || savingCountries"
            color="primary"
            @click="onSavePositionClick"
          >
            <v-icon left>mdi-content-save-outline</v-icon>
            <span>Save</span>
          </v-btn>

          <v-btn
            class="ml-6"
            icon
            @click="onToggleContent"
          >
            <v-icon v-if="!collapsed">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-expand-transition>
        <div v-if="!collapsed">
          <v-card-text>
            <GMapsMarkerManager
              v-model="markers"
              class="w-100"
              style="height: 30rem"
              draggable
              show-title
            />
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <ListView
      v-test-id="'countries'"
      title="Countries"
      :headers="headers"
      :service="service"
      searchable
      class="mt-3"
      hide-presets
      index="country"
      @load="onItemLoad"
    />
  </v-container>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, Ref} from 'vue-property-decorator';
import DataTable from '@/modules/common/components/DataTable.vue';
import GMapsMarkerManager, { IMarker } from '@/modules/common/components/GMapsMarkerManager.vue';
import ListView from '@/modules/common/components/ListView.vue';
import CountryService from '@/modules/sdk/services/country.service';
import CountryModel from '@/modules/sdk/models/country.model';

@Component({
  components: {
    ListView,
    DataTable,
    GMapsMarkerManager,
  }
})
export default class CountryList extends Vue {
  @Ref() readonly datatable!: DataTable

  name = 'CountryList';
  service = CountryService.getInstance();
  savingCountries = false
  collapsed = false
  countries: Array<CountryModel> = []
  markers: Array<IMarker> = []
  headers = [
    {
      text: '',
      align: 'start',
      sortable: false,
      fixed: true,
      width: 72,
      value: '_first',
      item: {
        type: 'button',
        attrs: {},
        icon: {
          text: 'mdi-pencil-outline'
        },
        to: {
          name: 'CountryForm',
          params: {
            id: 'props.item.data.id'
          }
        }
      }
    },
    {
      text: 'ID',
      align: 'start',
      sortable: true,
      fixed: true,
      value: 'id',
      filterable: {
        type: 'number',
      },
    },
    {
      text: 'Country',
      align: 'start',
      sortable: true,
      value: 'label',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Code',
      align: 'start',
      sortable: true,
      value: 'code',
      filterable: {
        type: 'text',
      }
    },
    {
      text: 'Latitude',
      align: 'start',
      sortable: true,
      value: 'latitude',
      filterable: {
        type: 'range',
      }
    },
    {
      text: 'Longitude',
      align: 'start',
      sortable: true,
      value: 'longitude',
      filterable: {
        type: 'range',
      }
    },
    {
      text: 'Deleted',
      align: 'start',
      sortable: true,
      value: 'deleted',
      filterable: {
        type: 'boolean'
      },
    },
    {
      text: '',
      align: 'start',
      sortable: false,
      value: '_last',
    },
  ];

  get markersAreDifferent(): boolean {
    return this.markers.filter((marker: any) => {
      return marker.position.lat !== marker.model.data.latitude
        || marker.position.lng !== marker.model.data.longitude;
    }).length > 0;
  }

  onResetPositionClick(): void {
    this.markers = this.parseMarkers(this.countries);
  }

  onSavePositionClick(): void {
    this.savingCountries = true;
    const countries = this.markers.map(marker => new CountryModel({
      ...marker.model.toObject(),
      latitude: marker.position.lat,
      longitude: marker.position.lng,
    }))
    CountryService.getInstance().save(countries)
      .then((response) => {
        this.countries = response.data.view.map((item: any) => new CountryModel(item.single));
        this.markers = this.parseMarkers(this.countries);
        if (this.datatable) {
          this.datatable.items = this.countries;
        }
        this.$root.$globalSnack.success({
          message: 'Country positions saved successfully!',
        });
      })
      .catch(reason => this.$root.$zemit.handleError(reason))
      .finally(() => this.savingCountries = false);
  }

  onToggleContent(): void {
    this.collapsed = !this.collapsed;
  }

  onItemLoad(items: Array<CountryModel>) {
    this.countries = items;
    this.markers = this.parseMarkers(items);
  }

  parseMarkers(countries: Array<CountryModel>): Array<IMarker> {
    return countries.map(country => ({
      model: country,
      position: {
        lat: country.data.latitude,
        lng: country.data.longitude,
      }
    }));
  }
}
</script>
