<template>
  <query-builder
    v-bind="$attrs"
    v-on="$listeners"
    :fields="filterListForQueryBuilder"
  />
</template>

<script lang="ts">
import 'reflect-metadata';
import QueryBuilder from '@/modules/common/components/QueryBuilder.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';

interface IFilter {
  label: string,
  formattedLabel: string,
  type: string,
  items: Array<string>,
}

@Component({
  components: {
    QueryBuilder,
  }
})
export default class TrackerQueryBuilder extends Vue {

  @Prop({ type: Array, default: () => ([]) }) filterList!: Array<IFilter>
  @Prop({ type: Array, default: () => ([]) }) studies!: Array<any>
  @Prop({ type: Array, default: () => ([]) }) legends!: Array<any>

  get sortedFilters(): Array<IFilter> {
    return this.filterList.sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get filterListForQueryBuilder(): Array<any> {
    const typeOfStudies: Array<any> = [];
    this.studies.forEach((column: any) => {
      typeOfStudies.push({
        category: 'study',
        operators: ['contains', 'does not contain'],
        text: column.name,
        value: '___' + column.name,
        type: 'select',
        items: this.legends,
      })
    })

    const filterList: Array<any> = this.sortedFilters.map(item => ({
      category: 'field',
      text: item.formattedLabel,
      value: item.label,
      type: 'list',
      items: item.items,
    }));

    if (typeOfStudies.length > 0) {
      filterList.unshift({ header: 'Fields' });
      return filterList.concat([
        { header: 'Types of studies' },
        ...typeOfStudies,
      ]);
    }

    return filterList;
  }
}
</script>
