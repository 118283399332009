import Model, { Data } from '@/modules/sdk/core/model';
import { heatmapLegendsList } from '@/enums/global';
import { opts } from '@/plugins/vuetify';

export default class HeatmapModel extends Model {

  default() {
    return {
      columns: [],
      legends: [],
    };
  }

  relatedMap() {
    return {}
  }

  uploadMap() {
    return {}
  }

  constructor(
    data: Data | Model = {}
  ) {
    super(data);
    this.data.columns = this.data.columns.map((column: any) => ({
      ...column,
      items: column.items.map((item: any) => ({
        ...item,
        match: item.match || {

        }
      }))
    }));

    heatmapLegendsList.forEach(heatmapLegend => {
      const color = ((opts as any).theme.themes.light[heatmapLegend.color] || '').toString().toUpperCase();
      if (!this.data.legends[color]) {
        this.data.legends[color] = heatmapLegend.text;
      }
    });
  }

}
