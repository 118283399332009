import Model from '@/modules/sdk/core/model';
import UserModel from '@/modules/sdk/models/user.model';
import TrackerUserModel from '@/models/tracker-user.model';
import Identity, {NestedArrayOr} from '@/modules/sdk/core/identity';
import HeatmapModel from '@/models/heatmap.model';
import FileModel from '@/modules/sdk/models/file.model';

export default class TrackerModel extends Model {

  default() {
    return {
      usernode: [],
      userlist: [],
      heatmap: {},
      datafileentity: new FileModel(),
      definitionfileentity: new FileModel(),
      abbreviationfileentity: new FileModel(),
    };
  }

  relatedMap() {
    return {
      usernode: TrackerUserModel,
      userlist: UserModel,
      heatmap: HeatmapModel,
      datafileentity: FileModel,
      definitionfileentity: FileModel,
      abbreviationfileentity: FileModel,
    }
  }

  relatedDeleteMissing() {
    return {
      usernode: true,
      userlist: false,
    };
  }

  jsonCast(): Array<string> {
    return [
      'heatmap',
    ]
  }

  uploadMap() {
    return {
      datafileentity: {
        key: 'dataFileId',
        category: 'tracker_data',
      },
      definitionfileentity: {
        key: 'definitionFileId',
        category: 'tracker_definition',
      },
      abbreviationfileentity: {
        key: 'abbreviationFileId',
        category: 'tracker_abbreviation',
      },
    }
  }

  hasRole(roleList: NestedArrayOr<string> = [], userId: number = Identity.getIdentity()?.user.id, or = false) {
    const userRoleList = [];
    for (const node of this.data.usernode) {
      if (node.data.userId === userId) {
        userRoleList.push(node.data.type);
      }
    }
    return Identity.has(roleList, userRoleList, or);
  }
}
