<template>
  <span>
    <template v-if="!visible">

    </template>
    <template v-else-if="showDiff">
      <template v-if="!splitRowMultiples(_value, header).multiple">
        <span v-if="header.fixed || firstValue === secondValue" v-text="secondValue || '-'"></span>
        <span v-else class="warning white--text" v-html="nl2br($options.filters?.parseLinks(_value, '_blank') || '-')"></span>
      </template>
      <DiffChipGroup
        v-else
        :first-value="firstValue"
        :second-value="secondValue"
        :header="header"
        :abbreviations="abbreviations"
        :show-diff="showDiff"
        :definitions="definitions"
      />
    </template>
    <template v-else>
      <span v-if="_value === true">Yes</span>
      <span v-else-if="_value === false">No</span>
      <span v-else-if="_value === ''">-</span>
      <span v-else-if="!splitRowMultiples(_value, header).multiple" v-html="nl2br($options.filters?.parseLinks(_value, '_blank'))"></span>
      <v-chip-group v-else column>
        <v-tooltip
          v-for="(value, valueIdx) in splitRowMultiples(_value, header).values.map(item => !item ? 'Undefined' : item)"
          :disabled="!getAbbreviation(row, header, value)"
          :key="valueIdx + value"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              v-html="$options.filters?.parseLinks(value, '_blank')"
              :key="value"
              label
              small
            ></v-chip>
          </template>
          <span v-text="getAbbreviation(row, header, value)"></span>
        </v-tooltip>
      </v-chip-group>
    </template>
  </span>
</template>

<script lang="ts">
import 'reflect-metadata';
import Utils from '@/modules/sdk/core/utils'
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import { IAbbreviation, IDefinition } from '@/interfaces';
import DiffChipGroup from '@/components/DiffChipGroup.vue';

@Component({
  components: {
    DiffChipGroup,
  }
})
export default class TrackerViewCell extends Vue {

  @VModel({ type: String, default: null }) model!: string | null
  @Prop({ type: Array, default: () => ([]) }) definitions!: Array<IDefinition>
  @Prop({ type: Array, default: () => ([]) }) abbreviations!: Array<IAbbreviation>
  @Prop({ type: String, default: null }) firstValue!: string
  @Prop({ type: String, default: null }) secondValue!: string
  @Prop({ type: Object, default: null }) header!: any
  @Prop({ type: Boolean, default: true }) visible!: boolean
  @Prop({ type: Boolean, default: false }) multiple!: boolean
  @Prop({ type: Object, default: null }) row!: {[key: string]: string}
  @Prop({ type: Boolean, default: false }) showDiff!: boolean

  get _value(): string | null | boolean {
    return this.model || this.row[this.header.text];
  }

  nl2br(text: string): string {
    if (text.startsWith('\'') && !text.endsWith('\'')) {
      // Replace newlines with <br> tags
      text = text.substring(1);
    }
    return text?.replace(/(\r\n|\n|\r)/g, '<br>');
  }

  getAbbreviation(row: any, header: any, value: string): string | false {
    const definition: IDefinition | undefined = this.definitions.find(definition => definition.name === header.text);
    if (!definition || !definition.abbreviation) {
      return false;
    }
    const abbreviation = this.abbreviations.find(abbr => {
      return (abbr.caseSensitive && abbr.abbreviation.replaceAll('*', '') === value.replaceAll('*', ''))
        || (!abbr.caseSensitive && (abbr.abbreviation || '').replaceAll('*', '').toLowerCase() === (value || '').replaceAll('*', '').toLowerCase());
    });
    return abbreviation?.definition || false;
  }

  splitValues(value: string): Array<string> {
    return Utils.splitIgnoringCommasInQuotes(value).map(item => item.split('\n')).flat();
  }

  splitRowMultiples(value: string | null | boolean, header: any) {
    return this.splitMultiples({
      label: header.text,
      value,
    }, header);
  }

  splitMultiples(row: any, header: any): {
    multiple: boolean,
    values: Array<string>,
  } {
    const definition: IDefinition | undefined = this.definitions.find(definition => definition.name === row.label);

    if (definition && !definition.single) {
      let items = this.splitValues(row.value);

      if (header.text === 'Info Source') {
        items = items.map(item => item.split(' ')).flat().map(item => item.trim())
      }

      return {
        multiple: true,
        values: items,
      };
    }
    return {
      multiple: false,
      values: [row.value],
    };
  }
}
</script>
