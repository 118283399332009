import Model, { Data } from '../core/model';
import merge from 'ts-deepmerge';
import { IQueryItem } from '../core/interfaces';

export interface IDashboardColor {
  name: string,
  value: string,
}

export interface IDashboardSerie {
  id?: string,
  name: string,
  colors: Array<string>,
  keys: Array<string>,
  field: string,
  labelFieldActive: boolean,
  labelField: string,
  yAxisField: string,
  xAxisField: string,
  markers: Array<string>,
}

export interface IDashboardChart {
  id: number | null,
  type: string | null,
  queryOverride: boolean,
  title: string | null,
  x: number,
  y: number,
  w: number,
  h: number,
  i: number,
  trackerId: number | null,
  projectId: number | null,
  projectLimit: number,
  colors: Array<IDashboardColor>,
  filters: Array<IQueryItem>,
  series: Array<IDashboardSerie>,
  manualSorting: boolean,
  indexes: { key: string, position: number }[],
  showPercentageAndNumber: boolean,
  generateFromField: boolean,
  allowCombineMultipleValues: boolean,
  combineMultipleValuesLabel: string | null,
  groupSerieBy: string | null,
  table: {
    groupBy: string | null,
    sortBy: Array<string>,
    sortDesc: Array<boolean>,
    itemsPerPage: number,
    headers: Array<{
      text: string,
      value: string,
      sortable: boolean,
    }>,
  },
  options: any,
  settings: any,
}

const defaultData = {
  id: null,
  type: null,
  queryOverride: false,
  title: null,
  options: {
    chart: {
      type: 'bar'
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      }
    },
    yAxis: {
      title: {
        text: null,
      }
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
        },
        showInLegend: false,
      },
      series: {
        stacking: false,
        dataLabels: {
          enabled: true
        }
      }
    },
    series: [],
  },
  colors: [],
  filters: [],
  settings: {
    scrollable: true,
  },
  table: {
    groupBy: null,
    sortBy: [],
    sortDesc: [],
    itemsPerPage: 10,
    headers: [],
  },
  series: [],
  manualSorting: false,
  indexes: [],
  allowCombineMultipleValues: false,
  combineMultipleValuesLabel: null,
  showPercentageAndNumber: false,
  generateFromField: false,
  groupSerieBy: null,
  trackerId: null,
  projectId: null,
  projectLimit: 100,
  x: 0,
  y: 0,
  w: 6,
  h: 8,
  i: 0,
};

export {
  defaultData
};

export default class DashboardChartModel extends Model {

  constructor(
    data: Data | Model = {}
  ) {
    super(data);
    this.data = merge(defaultData, this.data)
  }

  default(): IDashboardChart {
    return defaultData;
  }

  assign(data: Model | object, specificKeys = []) {
    return super.assign(data, specificKeys);
  }

  canCombineData(): boolean {
    return this.data.type === 'pie' && this.data.allowCombineMultipleValues
  }
}
