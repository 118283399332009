<template>
  <QueryGroupBuilder
    v-model="conditionList"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import 'reflect-metadata';
import { Vue, Component, VModel } from 'vue-property-decorator';
import QueryGroupBuilder from './QueryGroupBuilder.vue'

@Component({
  components: {
    QueryGroupBuilder,
  }
})
export default class QueryBuilder extends Vue {

  @VModel({ type: Array, default: () => [] }) conditionList!: any;

  clear() {
    this.conditionList.splice(0, this.conditionList.length, {
      logic: 'and',
      operator: 'contains',
      field: null,
      value: null,
      group: [] as any,
    });
  }

  created() {
    if (this.conditionList.length === 0) {
      this.clear();
    }
  }
}
</script>
