<template>
  <div ref="globalContainer">

    <ColorInput
      v-model="pointClickMenu.value"
      :visible.sync="pointClickMenu.visible"
      :position-x="pointClickMenu.x"
      :position-y="pointClickMenu.y"
      :suggestions="[
        $vuetify.theme.themes.light.success,
        $vuetify.theme.themes.light.warning,
        $vuetify.theme.themes.light.error,
      ]"
      :z-index="999"
      absolute
      offset-y
      hide-activator
      @input="onColorChange"
    />

    <v-data-table
      v-if="model.options.chart.type === 'datatable' && loaded"
      :headers="model.table.headers"
      :items="data"
      :items-per-page="model.table.itemsPerPage"
      :sort-by="model.table.sortBy"
      :sort-desc="model.table.sortDesc"
      :group-by="model.table.groupBy"
      :show-group-by="model.table.groupBy !== null"
      :height="containerHeight"
      fixed-header
    >
      <template #item="{ item, headers }">
        <tr>
          <td v-for="header in headers" :key="header.text">
            <template v-if="Array.isArray(getItems(item, header))">
              <v-chip-group column>
                <v-chip
                  v-for="value in getItems(item, header)"
                  v-html="value"
                  :key="value"
                  label
                  small
                ></v-chip>
              </v-chip-group>
            </template>
            <template v-else>
              {{ item[header.text] }}
            </template>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-else class="h-100 w-100">
      <div class="dashboard-chart-item-container" ref="container" :id="containerId"></div>
    </div>
  </div>
</template>

<script lang="ts">
import 'reflect-metadata';
import {Vue, Component, VModel, Watch, Prop, Ref} from 'vue-property-decorator'
import Highcharts from 'highcharts';
// import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartSankey from 'highcharts/modules/sankey';
import { IDashboardChart, IDashboardColor } from '@/modules/sdk/models/dashboard-chart.model';
import Hash from '@/modules/sdk/core/hash';
import merge from 'ts-deepmerge';
import ColorInput from '@/modules/common/components/ColorInput.vue';

// https://www.highcharts.com/demo/highcharts/sankey-diagram
// https://www.highcharts.com/demo/highcharts/box-plot
// https://www.highcharts.com/demo/highcharts/line-labels
// https://www.highcharts.com/demo/highcharts/column-basic
// https://www.highcharts.com/demo/highcharts/column-stacked-and-grouped
// https://www.highcharts.com/demo/highcharts/bar-stacked
// https://www.highcharts.com/demo/highcharts/pie-gradient
// https://www.highcharts.com/demo/highcharts/scatter

// HighchartsDrilldown(Highcharts);
HighchartsMore(Highcharts);
HighchartSankey(Highcharts);

@Component({
  components: { ColorInput }
})
export default class DashboardChartItem extends Vue {
  @Ref() readonly container!: HTMLElement
  @Ref() readonly globalContainer!: HTMLDivElement

  @VModel() model!: IDashboardChart;
  @Prop({ default: () => ([]) }) data!: Array<any>;
  @Prop({ type: Boolean, default: true }) isModel!: boolean;
  @Prop({ type: Boolean, default: false }) skipWatch!: boolean;
  @Prop({ type: Boolean, default: false }) canSelectColor!: boolean;
  @Prop({ type: Boolean, default: false }) combineMultiple!: boolean;
  @Prop({ type: String, default: null }) combineMultipleLabel!: string;
  @Prop({ default: () => null }) definitions!: Array<any> | null;

  updateTimeout = -1;
  loaded = false
  chart: any = null;
  containerHeight: number | null = null
  containerId = '';
  pointClickMenu: {
    visible: boolean,
    x: number,
    y: number,
    value: string | null,
    name: string | null,
  } = {
    visible: false,
    x: 0,
    y: 0,
    value: null,
    name: null,
  }

  destroyChart() {
    // if (this.chart) {
    //   this.chart.destroy();
    //   this.chart = null;
    // }
  }

  @Watch('combineMultiple')
  @Watch('model', { deep: true, })
  onModelChanged() {
    if (this.skipWatch) {
      return;
    }
    this.destroyChart();
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      if (this.model) {
        this.update();
      }
    }, 300);
  }

  @Watch('data', { deep: true, })
  onDataChanged() {
    if (this.skipWatch) {
      return;
    }
    this.destroyChart();
    clearTimeout(this.updateTimeout);
    this.updateTimeout = setTimeout(() => {
      if (this.model) {
        this.update();
      }
    }, 300);
  }

  onColorChange(color: string) {
    if (color) {
      const item = this.model.colors.find(color => color.name === this.pointClickMenu.name);
      if (item) {
        item.value = color;
      } else if (this.pointClickMenu.name) {
        this.model.colors.push({
          name: this.pointClickMenu.name,
          value: color,
        })
      }
    }
  }

  applyColors(series: any[], colors: IDashboardColor[], defaultColors: string[]) {
    let defaultColorIdx = 0;
    series.forEach(serie => {
      serie.colors = [];
      serie.data.forEach((item: any) => {
        let colorToApply = null;
        for (let i = 0; i < colors.length; i++) {
          const color = colors[i];
          const name = Array.isArray(item) ? item[0] : item.name;
          if (name === color.name) {
            colorToApply = color.value;
            break;
          }
        }
        if (colorToApply === null) {
          colorToApply = defaultColors[defaultColorIdx];
          defaultColorIdx++;
        }
        serie.colors.push(colorToApply);
      })
    })
  }

  getContainerHeight(): number | null {
    const ref = this.globalContainer;
    if (ref && ref.parentElement) {
      return ref.parentElement.offsetHeight - 75;
    }

    return null;
  }

  getItems(item: any, header: any) {
    const definition = this.definitions?.find(definition => definition.name === header.text);
    const multiple = definition && !definition.single;
    return multiple
      ? item[header.text].split(',')
      : item[header.text];
  }

  update(animate = false) {
    if (this.model.options.chart.type !== 'datatable') {
      try {
        this.destroyChart();
        const options = this.getOptions(this.model);
        options.chart.animation = animate;
        options.plotOptions.series.animation = animate;

        this.chart = Highcharts.chart(this.containerId, options);
        this.updateSize();
      } catch (e) {

      }
    }
  }

  updateSize() {
    const ref = this.container;
    if (this.chart && ref && ref.parentElement && ref.parentElement.parentElement && ref.parentElement.parentElement.parentElement) {
      const element = ref.parentElement.parentElement.parentElement;
      this.chart.setSize(
        element.clientWidth - 30,
        element.clientHeight - 18,
        false,
      );
    }

    this.containerHeight = this.getContainerHeight();
  }

  getRowsFromMetas(event: any, meta: any): any[] {
    const pointsToShow = [event.point];
    const pointNames = pointsToShow.map((point: any) => point.name || event.point.name);

    let multiple = false;
    if (this.definitions) {
      const definition = this.definitions.find(definition => definition.name === meta.name);
      multiple = definition && !definition.single;
    }

    const rowFilter = (row: any, field: string, values: Array<string>) => {
      const items = multiple
        ? row[field].split(',').map((entry: string) => entry.trim()).flat()
        : row[field];

      if (this.combineMultiple && items.length > 1) {
        return values.includes(this.combineMultipleLabel);
      }

      for (let i = 0; i < items.length; i++) {
        if (values.includes(items[i])) {
          return true;
        }
      }
      return false;
    }

    const rows = this.model.groupSerieBy
      ? (multiple
        ? this.data.filter((row: any) => row[meta.category] === event.point.category && rowFilter(row, meta.category, [meta.id]))
        : this.data.filter((row: any) => row[meta.category] === event.point.category && row[meta.field] === meta.id))
      : (multiple
        ? this.data.filter((row: any) => rowFilter(row, meta.field, pointNames))
        : this.data.filter((row: any) => pointNames.includes(row[meta.field])));

    return rows;
  }

  getOptions(model: IDashboardChart): any {

    const getDistinctData = (field: string) => {
      return [...new Set(this.data.map(item => {
        const objData: any = this.isModel ? item.data : item;
        // @ts-ignore
        return objData[field]
      }))];
    }

    const generateFromField = (serie: any, field: string) => {
      const uniques: Array<string> = [];
      const items = this.data.map(item => {
        const objData: any = this.isModel ? item.data : item;
        return objData[field];
      });
      items.forEach(item => {
        const splittedItems = item.split(',').map((entry: string) => entry.trim());
        splittedItems.forEach((splittedItem: string) => {
          if (uniques.indexOf(splittedItem) === -1) {
            uniques.push(splittedItem);
          }
        });
      })
      const response: Array<any> = [];
      uniques.forEach(unique => {
        const clone: any = structuredClone(serie);
        clone.name = unique;
        clone.meta = clone;
        response.push(clone);
      });
      return response;
    }

    const dataClick = (event: any, meta: any, points: Array<any>) => {
      if (this.canSelectColor) {
        const item = this.model.colors.find(color => color.name === event.point.name);
        Object.assign(this.pointClickMenu, {
          visible: true,
          x: event.clientX,
          y: event.clientY,
          value: item ? item.value : event.point.color,
          name: event.point.name,
        })
      } else {
        this.$emit('on-point-click', {
          rows: this.getRowsFromMetas(event, meta),
          meta,
          model,
          value: event.point.name,
          x: event.point.x,
          y: event.point.y,
          event,
          points,
          chart: this.chart,
        })
      }
    }

    const type = this.model.options.chart.type;
    const chart: any = {
      spacingLeft: 0,
      spacingTop: 5,
      spacingRight: 0,
      spacingBottom: 0,
      reflow: true,
      animation: false,
    };
    const defaultColors: string[] = [
      // @ts-ignore
      this.$vuetify.theme.themes.light.success.toString(),
      // @ts-ignore
      this.$vuetify.theme.themes.light.warning.toString(),
      // @ts-ignore
      this.$vuetify.theme.themes.light.error.toString(),
      '#DA70D6', '#8B4513', '#9400D3', '#40E0D0', '#CD5C5C', '#FFFFE0', '#4682B4', '#DEB887', '#D8BFD8', '#FDF5E6', '#F4A460', '#8FBC8F', '#6B8E23', '#F5F5F5', '#7B68EE', '#6A5ACD', '#0000CD', '#0000FF', '#E9967A', '#DAA520', '#C71585', '#000080', '#FF8C00', '#B22222', '#00FA9A',
    ];

    if (type === 'pie') {
      Object.assign(chart, {
        marginLeft: 0,
        marginTop: 0,
        marginRight: 0,
        // marginBottom: 0,
      })
    }
    else if (type === 'scatter') {
      chart.zoomType = 'xy';
    }

    // ts-merge unable to force typing on merging and we have an issue with
    // old data being incorrectly typed.
    if (Array.isArray(model.options.plotOptions.series)) {
      model.options.plotOptions.series = {};
    }

    // @ts-ignore
    const mergedOptions: any = merge({
      chart,
      title: false,
      yAxis: {},
      xAxis: {
        type: 'category',
        categories: [],
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: model.options.plotOptions.pie.dataLabels.enabled
            ? {
              enabled: true,
              format: model.showPercentageAndNumber ? '<b>{point.name}</b>: {point.percentage:.1f}% (n={point.y})' : null,
            }
            : {}
        },
        scatter: {
          marker: {
            radius: 2.5,
            symbol: 'circle',
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          jitter: {
            x: 0.005
          }
        },
        series: {
          // allowPointSelect: model.actAsFilter,
          cursor: 'pointer',
          dataSorting: {
            enabled: true,
            matchByName: true
          },
        },
      },
      tooltip: {
        shared: model.options.plotOptions.series.stacking,
      },
      series: [],
    }, model.options);

    mergedOptions.tooltip.pointFormatter = function() {
      if (model.options.yAxis.title.text && model.options.xAxis.title.text) {
        return ((model.options.yAxis.title.text || this.series.name) + ': <b>' + this.y + '</b><br/>' + (model.options.xAxis.title.text || this.series.name) + ': <b>' + this.x + '</b>') || null;
      }
      if (model.series.length === 1 && !model.groupSerieBy) {
        return 'Total: ' + '<b>' + this.y + '</b>';
      }

      return ((model.options.yAxis.title.text || this.series.name) + ': <b>' + this.y + '</b>') || null;
    }

    if (this.model.options.chart.type === 'boxplot' && this.model.groupSerieBy) {
      mergedOptions.xAxis.categories = getDistinctData(this.model.groupSerieBy);
    }
    else if (mergedOptions.plotOptions.series.stacking !== true) {
      mergedOptions.xAxis.categories = [];

      if (this.model.groupSerieBy) {
        mergedOptions.xAxis.categories = getDistinctData(this.model.groupSerieBy);
      }
    }

    if (this.model.options.chart.type === 'scatter') {
      Object.assign(mergedOptions.xAxis, {
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
      })
    }

    if (this.data.length > 0) {
      let series: Array<any> = this.model.series;

      // Generate series from field
      if (this.model.generateFromField) {
        series = generateFromField(this.model.series[0], this.model.series[0].field);
      } else if (this.model.groupSerieBy) {
        series = generateFromField(this.model.series[0], this.model.series[0].field);
      }

      const getSplittedFields = (value: any): Array<string> => {
        const items: Array<string> = [];
        const splittedItems: Array<string> = value.split(',').map((entry: string) => entry.trim());
        splittedItems.forEach(splittedItem => {
          if (!items.includes(splittedItem)) {
            items.push(splittedItem);
          }
        })
        return items;
      }

      const getSerieColors = (serie: any) => {
        let colorIndex = 0;
        const colors = [];
        colors.push(...defaultColors);
        serie.colors.forEach((color: string) => {
          colors[colorIndex] = color;
          colorIndex++;
          if (colors.length < colorIndex) {
            colorIndex = 0;
          }
        });
        return colors;
      }

      series.forEach((serie, serieIdx) => {
        serie.id = serie.name;
        serie.category = model.groupSerieBy;
        mergedOptions.series.push({
          name: serie.name,
          colorByPoint: !model.generateFromField,
          colors: !model.generateFromField ? getSerieColors(serie) : [],
          data: [],
        });
      });

      series.forEach((serie, serieIdx) => {
        const items: { [key: string]: number } = {};

        // Scatter chart
        if (this.model.options.chart.type === 'scatter') {
          const items: Array<[number, number]> = [];
          this.data.forEach(item => {
            const objData: any = this.isModel ? item.data : item;
            const fields = getSplittedFields(objData[serie.field]);
            fields.forEach(field => {
              const xVal = parseFloat(objData[serie.xAxisField]);
              const yVal = parseFloat(objData[serie.yAxisField]);
              if (field === serie.id && xVal > 0 && yVal > 0) {
                items.push([xVal, yVal]);
              }
            })
          });
          mergedOptions.series[serieIdx].data = items;
        }
        // Sankey chart
        else if (this.model.options.chart.type === 'sankey') {
          const items: Array<any> = [];
          this.data.forEach(item => {
            const objData: any = this.isModel ? item.data : item;
            const keyFrom = objData[serie.keys[0]];
            const splittedKeysFrom = getSplittedFields(keyFrom);
            const keyTo = objData[serie.keys[1]];
            const splittedKeysTo = getSplittedFields(keyTo);
            const value = parseFloat(objData[serie.keys[2]]);
            splittedKeysFrom.forEach(splittedKeyFrom => {
              splittedKeysTo.forEach(splittedKeyTo => {
                let index = items.indexOf((item: Array<any>) => item[0] === splittedKeyFrom);
                if (index === -1) {
                  items.push([
                    splittedKeyFrom,
                    splittedKeyTo,
                    0,
                  ])
                  index = items.length - 1;
                }
                items[index][2] += value;
              });
            })
          });
          mergedOptions.series[serieIdx].data = items;
        }
        // Box-plot
        else if (this.model.options.chart.type === 'boxplot') {
          const items: Array<Array<any>> = [];
          this.data.forEach(item => {
            const objData: any = this.isModel ? item.data : item;
            // @ts-ignore
            const index = mergedOptions.xAxis.categories.findIndex((item: string) => item === objData[this.model.groupSerieBy]);
            if (index === -1) {
              return;
            }
            if (!items[index]) {
              items[index] = [];
            }
            const fields = getSplittedFields(objData[serie.field]);
            fields.forEach(field => {
              items[index].push(parseInt(field));
            });
          });
          mergedOptions.series[serieIdx].data = items;
        }
        // Any grouped chart..
        else if (this.model.groupSerieBy) {

          // Initialize values to 0
          const distinctValues = getDistinctData(this.model.groupSerieBy);
          for (let j = 0; j < distinctValues.length; j++) {
            if (!series[serieIdx].data) {
              series[serieIdx].data = []
            }
            series[serieIdx].data[j] = null;
          }

          this.data.forEach(item => {
            const objData: any = this.isModel ? item.data : item;
            const index = mergedOptions.xAxis.categories.findIndex((value: string) => {
              // @ts-ignore
              return value === objData[this.model.groupSerieBy]
                && series[serieIdx].name === objData[series[serieIdx].field];
            });
            if (index === -1) {
              return;
            }
            if (series[serieIdx].data[index] === null) {
              series[serieIdx].data[index] = 0;
            }
            series[serieIdx].data[index]++;
          });

          mergedOptions.series[serieIdx].data = series[serieIdx].data;
        }
        // Any other chart
        else {

          let multipleTotal = 0;

          this.data.forEach(item => {
            const data: any = this.isModel ? item.data : item;
            const field = data[serie.field];
            if (field) {
              let multiple = false;
              if (this.definitions) {
                const definition = this.definitions.find(definition => definition.name === serie.field);
                multiple = definition && !definition.single;
              }
              if (multiple) {
                const splittedItems: Array<string> = field.split(',').map((entry: string) => entry.trim());
                if (this.combineMultiple && splittedItems.length > 1) {
                  multipleTotal++;
                  // multipleTotal += splittedItems.length;
                  return;
                }
                splittedItems.forEach(splittedItem => {
                  if (!items[splittedItem]) {
                    items[splittedItem] = 0;
                  }
                  items[splittedItem]++;
                })
              } else {
                if (!items[field]) {
                  items[field] = 0;
                }
                items[field]++;
              }
            }
          });
          if (this.combineMultiple && multipleTotal > 0) {
            items[this.combineMultipleLabel] = multipleTotal;
          }

          Object.keys(items).forEach(key => {
            mergedOptions.series[serieIdx].data.push([key, items[key]]);
          })
        }

        // Adjust data position
        if (model.manualSorting) {
          mergedOptions.series[serieIdx].data.forEach((item: any, itemIdx: number) => {
            const index = model.indexes.find(index => index.key === item[0]);
            if (index) {
              mergedOptions.series[serieIdx].data[itemIdx] = {
                name: item[0],
                index: index.position,
                x: itemIdx,
                y: item[1],
              }
            }
          })
          mergedOptions.series[serieIdx].data.sort((a: any, b: any) => {
            return (a.index > b.index) ? -1 : 1;
          });
          mergedOptions.series[serieIdx].dataSorting = {
            enabled: true,
            sortKey: 'index',
          };
        }

        mergedOptions.series[serieIdx].point = {
          events: {
            click: (e: any) => {
              setTimeout(() => {
                const points = this.chart.getSelectedPoints();
                dataClick(e, serie, points);
              })
            }
          }
        }
      })

      if (model.generateFromField) {
        Highcharts.setOptions({
          // @ts-ignore
          colors: getSerieColors(series[0]),
        });
        series.forEach((serie, serieIdx) => {
          delete mergedOptions.series[serieIdx].colors;
        })
        series[0].markers.forEach((marker: any, markerIdx: number) => {
          if (mergedOptions.series[markerIdx]) {
            mergedOptions.series[markerIdx].marker = {
              symbol: marker,
            }
          }
        })
      }
    }

    if (['scatter'].includes(this.model.options.chart.type || '')) {
      delete mergedOptions.yAxis.min;
      delete mergedOptions.xAxis.min;
      delete mergedOptions.xAxis.type;
      delete mergedOptions.xAxis.crosshair;
      delete mergedOptions.xAxis.categories;
    }

    this.applyColors(mergedOptions.series, this.model.colors, defaultColors);

    return mergedOptions;
  }

  created(): void {
    this.containerId = Hash.guid();

    window.addEventListener('resize', this.updateSize);
  }

  destroyed(): void {
    this.destroyChart();
    window.removeEventListener('resize', this.updateSize);
  }

  mounted(): void {
    if (this.model.options.chart.type !== 'datatable') {
      const options = this.getOptions(this.model);
      this.chart = Highcharts.chart(this.containerId, options);
    }

    this.updateSize();
    this.loaded = true;
  }
}
</script>

<style lang="scss" scoped>
.dashboard-chart-item-container {
  ::v-deep rect[fill="#ffffff"] {
    fill: none !important;
  }
}
</style>
